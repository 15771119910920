import React from "react";
import { Collapse } from "react-collapse";
import { DateFieldInput, GenderField, TextField } from "../../form";
import { SimilarResidents } from "./atoms/SimilarResidents";
import { NewResident } from "./atoms/NewResident";
import { H2 } from "../../text";
import { useFormikContext } from "formik";
import { NEW_RESIDENT } from "./atoms/types";
import { theme } from "../../theme";
import { FlexRow } from "../../box";
import { ResidentNotMatchingStatusBannerOnClosed, ResidentMightExistStatusBannerOnClosed, } from "./atoms/styles";
import { MatchedResidentInfo } from "./atoms/MatchedResidentInfo";
import { ManageResidentButton } from "./atoms/ManageResidentButton";
import { ToggleCollapseButton } from "./atoms/ToggleCollapseButton";
export var BlockDemographics = function (_a) {
    var _b, _c, _d, _e, _f;
    var queryVariables = _a.queryVariables, similarResidentsControl = _a.similarResidentsControl;
    var resident_demographics = useFormikContext().values.resident_demographics;
    var matchedResident = similarResidentsControl.items.find(function (item) { return item.resident.id === resident_demographics.similar_resident; });
    var isSelectedResidentFullMatch = function (id) {
        var resident = similarResidentsControl.items.find(function (item) { return item.resident.id === id; });
        return resident ? resident.match === "100%" : false;
    };
    var isOpen = similarResidentsControl.openDemographics;
    var toggleOpen = similarResidentsControl.setOpenDemographics;
    return (React.createElement(React.Fragment, null,
        React.createElement(TextField, { label: "First Name", name: "resident_demographics.resident_firstName" }),
        React.createElement(TextField, { label: "Last Name", name: "resident_demographics.resident_lastName" }),
        React.createElement(GenderField, { label: "Gender", name: "resident_demographics.resident_gender" }),
        React.createElement(DateFieldInput, { label: "Date of Birth", name: "resident_demographics.resident_dateOfBirth" }),
        React.createElement("div", { style: {
                marginBottom: "16px",
                marginTop: "32px",
                border: "2px solid ".concat(theme.color.borderGray),
                borderRadius: "4px",
                padding: "16px",
                paddingBottom: !isOpen &&
                    !isSelectedResidentFullMatch(resident_demographics.similar_resident)
                    ? "56px"
                    : "24px",
                position: "relative",
            } },
            React.createElement(FlexRow, null, !similarResidentsControl.disableToggle ? (React.createElement(React.Fragment, null,
                React.createElement(H2, { style: { marginBottom: "24px" } }, isOpen ? "New Resident Profile" : "Resident Profile"),
                React.createElement(ToggleCollapseButton, { isOpen: isOpen, toggleOpen: toggleOpen }))) : (React.createElement(React.Fragment, null,
                React.createElement(H2, { style: { marginBottom: "24px" } }, "Resident File"),
                React.createElement(ManageResidentButton, { linkedResidentId: ((_b = similarResidentsControl.linkedResident) === null || _b === void 0 ? void 0 : _b.id) ||
                        resident_demographics.similar_resident })))),
            !isOpen && (React.createElement(MatchedResidentInfo, { matchedResident: matchedResident, firstName: ((_c = similarResidentsControl.linkedResident) === null || _c === void 0 ? void 0 : _c.first_name) ||
                    resident_demographics.resident_firstName, lastName: ((_d = similarResidentsControl.linkedResident) === null || _d === void 0 ? void 0 : _d.last_name) ||
                    resident_demographics.resident_lastName, gender: ((_e = similarResidentsControl.linkedResident) === null || _e === void 0 ? void 0 : _e.gender) ||
                    resident_demographics.resident_gender, dateOfBirth: ((_f = similarResidentsControl.linkedResident) === null || _f === void 0 ? void 0 : _f.date_of_birth) ||
                    resident_demographics.resident_dateOfBirth, similar_resident: resident_demographics.similar_resident })),
            similarResidentsControl.disableToggle
                ? null
                : !isSelectedResidentFullMatch(resident_demographics.similar_resident) &&
                    resident_demographics.similar_resident !== NEW_RESIDENT &&
                    !isOpen && React.createElement(ResidentNotMatchingStatusBannerOnClosed, null),
            resident_demographics.similar_resident === NEW_RESIDENT &&
                similarResidentsControl.items.length > 0 &&
                !isOpen && React.createElement(ResidentMightExistStatusBannerOnClosed, null),
            React.createElement(Collapse, { isOpened: isOpen },
                React.createElement(NewResident, { hasSimilarResidents: similarResidentsControl.items.length > 0 }),
                React.createElement(SimilarResidents, { items: similarResidentsControl.items, modal: similarResidentsControl.modal })))));
};

import React from "react";
import {AdmissionTemplateVersion} from "@reside/reside-api-document";
import {Admission} from "@reside/reside-api-admission";
import {useHistory} from "react-router-dom";
import {Template} from "@reside/forms";
import {
  BlockComponentContextProvider,
  FlexRow,
  H1,
  useSafeSetState,
} from "@reside/ui";

import {SimilarResidentsContextProvider} from "./context/SimilarResidentsContext";
import {preflightBlockComponents, PreFlightForm} from "./atoms/PreFlightForm";
import {PccSearchIndicator} from "../../atoms/pcc-search-indicator";
import {AdmissionStatus} from "../../services/AdmissionsService";
import {selectMenuProps} from "./atoms/menu/selectMenuProps";
import {usePreflight} from "./utils/hooks/usePreflight";
import {components} from "../../atoms/block-components";
import {PccModal} from "../../atoms/pcc-modal";
import {Menu} from "./atoms/menu";
import {
  changePccStatusToActive,
  PccPatientDetail,
} from "../../services/PccService";
import {
  getInitialState,
  mapAdmissionAnswersToValues,
  pccPatientDetailToPreflightAnswers,
} from "./utils/helpers/helpers";

const blockComponents = {...preflightBlockComponents, ...components};

export const PreflightLayout = ({
  admission = {} as Admission,
  admissionId,
  facilityId,
  isPointClickCareEnabled,
  facilitySettings,
  template,
  version = {} as AdmissionTemplateVersion,
}: {
  admission?: Admission;
  admissionId?: string;
  facilityId: string;
  isPointClickCareEnabled: boolean;
  facilitySettings?: any;
  template: Template;
  version: AdmissionTemplateVersion;
}) => {
  const history = useHistory();

  const values = {
    ...facilitySettings,
    ...mapAdmissionAnswersToValues(admission.answers),
  };

  const [admissionDraft, setAdmissionDraft] = useSafeSetState<Admission>(
    getInitialState({
      ...admission,
      facilityId,
      admissionId,
      templateId: version.id,
      templateName: version.templateName,
    }),
  );

  const preflight = usePreflight({
    admissionDraft,
    queryVariables: facilitySettings,
    template,
    setAdmissionDraft,
    values,
  });

  const onSearchResults = async (data: PccPatientDetail) => {
    const {preflightAnswers} = pccPatientDetailToPreflightAnswers(data);

    await preflight.setAnswers({
      ...preflight.answers,
      ...preflightAnswers,
    });
  };

  const admissionWasCreated = !!(
    preflight.hasSetInProgress &&
    admissionDraft.status === AdmissionStatus.IN_PROGRESS
  );

  const {
    resident_firstName: firstName,
    resident_lastName: lastName,
    resident_gender: gender,
    resident_dateOfBirth: birthDate,
    resident_demographics,
  } = preflight.answers;

  return (
    <>
      <H1>Edit Pre-Flight</H1>
      <FlexRow>
        {preflight.slides.length > 0 && (
          <SimilarResidentsContextProvider
            isPointClickCareEnabled={isPointClickCareEnabled}
          >
            <>
              <Menu {...selectMenuProps(preflight)} />
              <div style={{width: "100%"}}>
                {isPointClickCareEnabled && (
                  <PccSearchIndicator
                    firstName={
                      resident_demographics?.resident_firstName ||
                      (firstName as string)
                    }
                    lastName={
                      resident_demographics?.resident_lastName ||
                      (lastName as string)
                    }
                    gender={
                      resident_demographics?.resident_gender ||
                      (gender as string)
                    }
                    birthDate={
                      resident_demographics?.resident_dateOfBirth ||
                      (birthDate as string)
                    }
                    facilityId={facilityId}
                    onSearchResults={onSearchResults}
                  />
                )}
                <BlockComponentContextProvider value={blockComponents}>
                  <PreFlightForm
                    {...preflight}
                    admissionDraft={preflight.draft}
                    queryVariables={facilitySettings}
                  />
                </BlockComponentContextProvider>
              </div>
              {isPointClickCareEnabled && admissionWasCreated && (
                <PccModal
                  admissionId={admissionDraft.id}
                  facilityId={admissionDraft.facilityId}
                  onRequestClose={() => {
                    history.push("/admin/dashboard");
                  }}
                  onAccountExists={() =>
                    changePccStatusToActive(admissionDraft.id)
                  }
                />
              )}
            </>
          </SimilarResidentsContextProvider>
        )}
      </FlexRow>
    </>
  );
};

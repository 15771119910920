import {residentsHttp} from "./api/residentApiClient";
import {getAuthorizationHeader} from "../models/admin-session/selectors";
import {
  Resident,
  ResidentStatus,
  ResidentSubStatus,
} from "../pages/page-admin-residents/types";
import {CreateResidentDto} from "../pages/page-admin-residents-forms/types";
import {Value} from "@reside/forms";

export const SORT_RESIDENTS_BY = [
  "name",
  "date_of_birth",
  "created",
  "created_by",
] as const;

type SearchResidents = {
  facility_ids?: string[];
  search?: string;
  page?: number;
  page_size?: number;
  sort_by?: (typeof SORT_RESIDENTS_BY)[number];
  sort_direction?: "asc" | "desc";
  resident_status?: Array<ResidentStatus | ResidentSubStatus>;
  is_archived?: boolean;
  waiting_list?: boolean;
};
type LinkResidentToAdmission = {
  admission_id: string;
  resident_id: string;
};

/** Data required when looking up similar residents. */
export type SearchSimilarResidents = {
  organization_id: string;
  first_name: string | Value;
  last_name: string | Value;
  gender: string | Value;
  date_of_birth: Date | string;
};

export const ResidentService = {
  getResidentsViaPost: (body: SearchResidents) =>
    residentsHttp.post<{data: Resident[]; total_count: number}>(
      "/search/",
      body,
      {
        headers: {Authorization: getAuthorizationHeader()},
      },
    ),
  linkResidentToAdmission: (body: LinkResidentToAdmission) =>
    residentsHttp.post<Resident[]>("/link-admission/", body, {
      headers: {Authorization: getAuthorizationHeader()},
    }),
  getOneResident: (resident_uuid: string) =>
    residentsHttp.get<Resident>(`/${resident_uuid}/`, {
      headers: {Authorization: getAuthorizationHeader()},
    }),
  createResident: (body: CreateResidentDto) =>
    residentsHttp.post<Resident>("/", body, {
      headers: {Authorization: getAuthorizationHeader()},
    }),
  editResident: (body: CreateResidentDto, resident_uuid: string) =>
    residentsHttp.put<Resident>(`/${resident_uuid}/`, body, {
      headers: {Authorization: getAuthorizationHeader()},
    }),
  activateResident: (resident_uuid: string) =>
    residentsHttp.patch<Resident>(`/${resident_uuid}/activate/`, {
      headers: {Authorization: getAuthorizationHeader()},
    }),
  inactivateResident: (resident_uuid: string) =>
    residentsHttp.delete<Resident>(`/${resident_uuid}/`, {
      headers: {Authorization: getAuthorizationHeader()},
    }),
  getSimilarResidentsViaPost: (body: SearchSimilarResidents) =>
    residentsHttp.post<{resident: Resident; match: string}[]>(
      "/list-duplicates/",
      body,
      {
        headers: {Authorization: getAuthorizationHeader()},
      },
    ),
  getLinkedResidentId: (admission_uuid: string) =>
    residentsHttp.get<{admission_id: string; resident_id: string | null}>(
      `/admission/${admission_uuid}/`,
      {
        headers: {Authorization: getAuthorizationHeader()},
      },
    ),
};

import React from "react";
import {Button, BlockField} from "@reside/ui";
import {useFormikContext} from "formik";
import {Values} from "@reside/forms";
import {connect} from "react-redux";
import {Trans} from "@lingui/macro";

import {ResidentAdmissionsButtonWithModal} from "../../../atoms/resident-admissions-modal/ResidentAdmissionsButtonWithModal";
import {useSimilarResidentsContext} from "../context/SimilarResidentsContext";
import {AdmissionStatus} from "../../../services/AdmissionsService";
import {logErrorToSentry} from "../../../utils/sentry";
import {Row} from "../../page-admin-residents/types";
import {AppState, select} from "../../../store";
import {PreFlightFieldsetProps} from "./types";
import {ButtonSpace} from "./styles";
import {usePreflightRouteParams} from "../route";
import {useFetchLinkedResident} from "../../page-admin-residents/hooks/useFetchLinkedResident";
import {useLinkResidentOnAdmissionCreation} from "./hooks/useLinkResidentOnAdmissionCreation";
import {useHandleSimilarResidents} from "./hooks/useHandleSimilarResidents";
/**
 * Group of related fields used to create or edit pre-flight
 */

export const PreFlightFieldsetRenderer = ({
  admissionDraft,
  isLastSlide,
  uiActions,
  currentSlide,
  queryVariables,
  isPatchingDraft,
  isSavingDraft,
  isEditing,
  isPatchingAdmission,
  isSendingToResident,
  createDraft,
  patchDraft,
  createAdmission,
  patchAdmission,
  hasSetInProgress,
  organizationId,
}: PreFlightFieldsetProps): JSX.Element => {
  const {admissionId} = usePreflightRouteParams();
  const {initialValues} = useFormikContext<Values>();

  const {
    fetching,
    isPointClickCareEnabled,
    openDemographics,
    setOpenDemographics,
  } = useSimilarResidentsContext();

  const inPreflightStatus =
    admissionDraft.status === AdmissionStatus.PRE_FLIGHT;

  const isAdmissionCreated = !!(
    hasSetInProgress && admissionDraft.status === AdmissionStatus.IN_PROGRESS
  );

  const {data: linkedResident, isFetching: isFetchingLinkedResident} =
    useFetchLinkedResident(admissionId);

  useLinkResidentOnAdmissionCreation(
    isAdmissionCreated,
    admissionDraft,
    linkedResident,
    isPointClickCareEnabled,
  );

  useHandleSimilarResidents(fetching); // does nothing if no resident demographics fieldset was rendered

  const hasAllDemographicsFields =
    initialValues.resident_demographics &&
    Object.values(initialValues.resident_demographics).every(Boolean);

  return (
    <fieldset
      disabled={
        isSendingToResident ||
        isPatchingAdmission ||
        isPatchingDraft ||
        isSavingDraft
      }
    >
      {/* fieldset firefox implementation requires wrapping children in extra div */}
      <div>
        {currentSlide.children.map(block => {
          return (
            <BlockField
              key={block.id}
              block={block}
              queryVariables={queryVariables}
              onCatch={logErrorToSentry}
              similarResidentsControl={{
                items: fetching.similarResidents || [],
                modal: ({rowData}: Row) => (
                  <ResidentAdmissionsButtonWithModal rowData={rowData} />
                ),
                organizationId,
                openDemographics:
                  (isEditing && isPointClickCareEnabled) ||
                  (isEditing && hasAllDemographicsFields)
                    ? false
                    : openDemographics, // Never open dropdown in edit mode
                setOpenDemographics,
                isEditing,
                linkedResident,
                isFetchingLinkedResident,
                disableToggle:
                  (isEditing && isPointClickCareEnabled) ||
                  (isEditing && hasAllDemographicsFields),
              }}
            />
          );
        })}
        <ButtonSpace>
          {inPreflightStatus && !isEditing && (
            <Button
              testId="saveDraftButton"
              onClick={() => createDraft()}
              disabled={isSavingDraft || isSendingToResident}
            >
              {isSavingDraft ? "Saving Pre-Flight..." : "Save Pre-Flight"}
            </Button>
          )}
          {inPreflightStatus && isEditing && (
            <Button
              testId="editDraftButton"
              onClick={() => patchDraft()}
              disabled={isPatchingDraft || isSendingToResident}
            >
              {isPatchingDraft ? "Saving Pre-Flight..." : "Save Pre-Flight"}
            </Button>
          )}
          {!isLastSlide && inPreflightStatus && (
            <Button
              testId="nextSlideButton"
              color="primary"
              onClick={() => uiActions.goToNextSlide()}
            >
              <Trans id={currentSlide.ctaButton.title.translationKey} />
            </Button>
          )}
          {isLastSlide && inPreflightStatus && (
            <Button
              testId="sendToResidentButton"
              color="primary"
              onClick={() => createAdmission()}
              disabled={isPatchingDraft || isSavingDraft || isSendingToResident}
            >
              {isSendingToResident ? (
                "Sending Pre-Flight..."
              ) : (
                <Trans id={currentSlide.ctaButton.title.translationKey} />
              )}
            </Button>
          )}
          {!inPreflightStatus && (
            <Button
              testId="editAdmissionButton"
              color="primary"
              onClick={() => patchAdmission()}
              disabled={isPatchingAdmission}
            >
              {isPatchingAdmission ? "Sending update..." : "Save changes"}
            </Button>
          )}
        </ButtonSpace>
      </div>
    </fieldset>
  );
};

const mapStateToProps = (state: AppState) => ({
  organizationId: select.adminSession.user(state).organization.id,
});

export const PreFlightFieldset = connect(mapStateToProps)(
  PreFlightFieldsetRenderer,
);

import React from "react";
import {Text, theme} from "@reside/ui";

import {UserIcon} from "../../../atoms/resident-admissions-modal/styles";

export const CardNodeResident = ({
  firstName,
  lastName,
  organizationName,
  isResideAdmin,
}: {
  firstName: string;
  lastName: string;
  organizationName: string;
  isResideAdmin: boolean;
}): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        marginLeft: "8px",
      }}
    >
      <div>
        <Text color="darkBlue100" fontSize={3} fontWeight="bold">
          {`${firstName} ${lastName}`}
        </Text>
      </div>
      {isResideAdmin && (
        <div>
          <UserIcon size={14} color={theme.color.gray100} name="user" />
          <Text color="gray100">{organizationName}</Text>
        </div>
      )}
    </div>
  );
};

import {useQuery} from "react-query";
import Alert from "react-s-alert";
import {Template} from "@reside/forms";

import {dispatch} from "../store";
import {DocumentService} from "../services/DocumentService";
import {buildResponseErrorMessage} from "../services/api/errorHelpers";
import defaultResidentExperiencePreludeTemplate from "../pages/page-admission-prelude/mocks/IL2019_resident-experience-prelude.json";
import defaultPreflightTemplate from "../pages/page-admin-preflight/utils/helpers/IL2019_pre-flight.json";

const createUseTemplateQuery =
  ({
    getTemplate = DocumentService.getFacilitySettingsTemplate,
    defaultTemplate,
    queryKey,
  }: {
    getTemplate?: typeof DocumentService.getFacilitySettingsTemplate;
    defaultTemplate?: Template;
    queryKey?: string;
  }) =>
  ({versionId}: {versionId?: string}) =>
    useQuery(
      [queryKey ? queryKey : "templateByVersion", versionId],
      async () => {
        const {data: template} = await getTemplate(versionId);

        // HACK: must be set before the template is rendered. Otherwise UUIDs will be used in place of placeholders.
        dispatch.locale.mergeMessagesIntoCatalogs({template});

        return template as any as Template;
      },
      {
        enabled: Boolean(versionId),
        onError: async (error: any) => {
          // Error retrieving file from S3 storage. No reference file exists for given template
          const missingTemplateFileForVersion = 404; //RESIDE-2855 setup specialized code from BE (backwards compatibility)

          if (error.status === missingTemplateFileForVersion) {
            return defaultTemplate;
          }

          Alert.error(
            await buildResponseErrorMessage(
              error,
              "Could not load template. Please, try again later.",
            ),
          );

          throw error;
        },
      },
    );

export const useFacilitySettingsTemplateQuery = createUseTemplateQuery({
  getTemplate: DocumentService.getFacilitySettingsTemplate,
  queryKey: "facilitySettingsTemplateByVersion",
});

export const usePreludeTemplateQuery = createUseTemplateQuery({
  getTemplate: DocumentService.getPreludeExperienceTemplate,
  defaultTemplate: defaultResidentExperiencePreludeTemplate as any as Template,
});

export const usePreflightTemplateQuery = createUseTemplateQuery({
  getTemplate: DocumentService.getPreflightTemplate,
  defaultTemplate: defaultPreflightTemplate as any as Template,
});

export const useTemplateByIdQuery = createUseTemplateQuery({
  getTemplate: DocumentService.getTemplate,
  queryKey: "fullTemplate",
});

import { format, isValid, parse } from "date-fns";
export var getAge = function (birthDate) {
    return Math.floor((new Date().getTime() - new Date(birthDate).getTime()) / 3.15576e10);
};
export var parseDate = function (date, fallback) {
    if (fallback === void 0) { fallback = "—"; }
    if (!isValid(parse(date, "yyyy-MM-dd", new Date())))
        return fallback; // Display fallback string to prevent empty cells
    return format(parse(date, "yyyy-MM-dd", new Date()), "MM/dd/yyyy");
};

import React, {useState, useMemo, useCallback, FormEvent} from "react";
import RcTree from "rc-tree";
import {cx} from "@emotion/css";
import {flatMap, isEqual} from "lodash";
import {useIsFetching} from "react-query";
import {DataNode} from "rc-tree/lib/interface";
import {Icon, ThinScrollbar, FlexRow, Button} from "@reside/ui";

import {GeneralSearch} from "../search-box";

import "./TreeSelect.scss";
import {excludeParentNodes, handleKeyDown} from "./helpers";
import {TreeSelectStyledClasses} from "./styled";

export type Props = Readonly<{
  defaultSearchValue?: string;
  checkedKeys: ReadonlyArray<string>;
  treeData: ReadonlyArray<DataNode & {className?: string}>;
  EmptySearch?: () => JSX.Element;
  searchPlaceholder?: string;
  // Callback for setting checked keys in global state.
  onCheck: (checkedKeys: string[], checkedLeafKeys: string[]) => void;
  hasOnlyOne?: boolean;
  autoComplete?: string;
  withSearch?: boolean;
  // Controls whether selections are applied immediately or require confirmation via Apply button
  withApplyFiltersButton?: boolean;
}>;

export const TreeSelect = ({
  defaultSearchValue = "",
  checkedKeys = [],
  treeData,
  EmptySearch = () => <span>No matching items</span>,
  searchPlaceholder = "Search...",
  onCheck,
  hasOnlyOne,
  autoComplete,
  withSearch = true,
  withApplyFiltersButton = false,
}: Props) => {
  // Tracks pending selections when using Apply Filters mode
  const [pendingCheckedKeys, setPendingCheckedKeys] =
    useState<readonly string[]>(checkedKeys);

  // Track whether any queries are currently fetching data. Used to disable the Apply button while data is being loaded.
  const isLoadingData = Boolean(useIsFetching());

  const areSelectionsUnchanged = useMemo(
    () => {
      const areEqual = isEqual(
        [...pendingCheckedKeys].sort((a, b) => a.localeCompare(b)),
        [...checkedKeys].sort((a, b) => a.localeCompare(b)),
      );

      return areEqual;
    },
    // length of pendingCheckedKeys and checkedKeys are the correct dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      pendingCheckedKeys.length,
      checkedKeys.length,
      isLoadingData, // Re-evaluate when loading state changes in case user changed filters while data was loading
    ],
  );

  const [searchValue, handleSearch] = useState(defaultSearchValue);
  const isSearchEmpty = searchValue === "";
  const filterValue = searchValue.toLowerCase();

  // Flatten the tree structure into a single array containing all nodes
  const nodes = useMemo(
    () => flatMap(treeData, node => [node, ...node.children]),
    [treeData],
  );

  // Get all keys from the tree (both parent and child nodes)
  const allKeys = useMemo(() => nodes.map(({key}) => key), [nodes]);

  // Leaf keys are those that don't appear as parent nodes
  const allLeafKeys = useMemo(
    () => allKeys.filter(key => !treeData.find(node => node.key === key)),
    [allKeys, treeData],
  );

  // Filter nodes based on search text
  const filterKeys = useMemo(
    () =>
      isSearchEmpty
        ? []
        : nodes
            .filter(({title}) => title.toLowerCase().includes(filterValue))
            .map(({key}) => key),
    [nodes, filterValue, isSearchEmpty],
  );

  // Select all visible items (either all items or only filtered items if searching)
  const handleSelectAll = useCallback(() => {
    if (withApplyFiltersButton) {
      setPendingCheckedKeys(isSearchEmpty ? allKeys : filterKeys);
    } else {
      onCheck(
        isSearchEmpty ? allKeys : filterKeys,
        isSearchEmpty ? allLeafKeys : filterKeys,
      );
    }
  }, [
    onCheck,
    allKeys,
    allLeafKeys,
    filterKeys,
    isSearchEmpty,
    withApplyFiltersButton,
  ]);

  const handleClear = useCallback(() => {
    if (withApplyFiltersButton) {
      setPendingCheckedKeys([]); // Currently clear all filters. You can set this to reset filters any state you want.
    } else {
      onCheck([], []); // Commence search with empty filters leaf keys and checked keys.
    }
  }, [onCheck, withApplyFiltersButton, setPendingCheckedKeys]);

  // Returns checked keys based on the current mode (single select or apply filters)
  const getCheckedKeysBasedOnMode = () => {
    if (hasOnlyOne) {
      return undefined;
    }

    return withApplyFiltersButton ? pendingCheckedKeys : checkedKeys;
  };

  // Handles checked keys and filters out non-checkable parent nodes
  const handleCheckedKeys = useCallback(
    (keys: readonly string[]) => {
      // Filter out parent nodes marked as not checkable
      const checkedWithoutUncheckable = keys.filter(checkedKey => {
        return (
          (treeData.find(data => data.key === checkedKey) as any)?.checkable !==
          false
        );
      });

      onCheck(
        hasOnlyOne ? [] : checkedWithoutUncheckable,
        checkedWithoutUncheckable.filter(checkedKey =>
          allLeafKeys.includes(checkedKey),
        ),
      );
    },
    [onCheck, hasOnlyOne, treeData, allLeafKeys],
  );

  return (
    <div
      className={cx(TreeSelectStyledClasses.treeSelect, {
        "single-select-mode": hasOnlyOne,
      })}
    >
      {withSearch && (
        <GeneralSearch
          hideSuggestion
          isSearching={false}
          inputValue={searchValue}
          showClearButton={!isSearchEmpty}
          onInputChange={(event: FormEvent<HTMLInputElement>) =>
            handleSearch(event.currentTarget.value)
          }
          onPressSpace={(event: FormEvent<HTMLInputElement>) => {
            if (searchValue.trim().length > 0) handleSearch(`${searchValue} `);
          }}
          placeholder={searchPlaceholder}
          onClearClick={() => handleSearch("")}
          autoComplete={autoComplete}
        />
      )}
      {!hasOnlyOne && (
        <div className={TreeSelectStyledClasses.macroActions}>
          <span
            onClick={handleSelectAll}
            onKeyDown={handleKeyDown(handleSelectAll)}
            role="button"
            tabIndex={0} // make span focusable for keyboard users
          >
            Select All
          </span>
          {!withApplyFiltersButton && " | "}
          {!withApplyFiltersButton && (
            <span
              onClick={handleClear}
              onKeyDown={handleKeyDown(handleClear)}
              role="button"
              tabIndex={0} // make span focusable for keyboard users
            >
              Clear
            </span>
          )}
        </div>
      )}
      {!isSearchEmpty && filterKeys.length === 0 && (
        <div className={TreeSelectStyledClasses.emptySearch}>
          <EmptySearch />
        </div>
      )}
      <ThinScrollbar>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        <RcTree
          multiple={!hasOnlyOne}
          autoExpandParent
          checkable={
            !hasOnlyOne && <Icon name="check" color="white" size={12} />
          }
          treeData={treeData as DataNode[]}
          selectable={hasOnlyOne}
          onSelect={
            hasOnlyOne &&
            (keys => {
              // We exclude the parent items, so only leaf items are selectable
              const checkedLeafKeys = excludeParentNodes(
                keys as string[],
                allLeafKeys,
              );

              // When already selected item is clicked, it's unselected (the checkedLeafKeys is empty)
              // so we ignore the unselect as the tree select is in single-select mode
              if (checkedLeafKeys.length) {
                onCheck(undefined, checkedLeafKeys as string[]);
              }
            })
          }
          selectedKeys={hasOnlyOne ? checkedKeys : []}
          expandedKeys={isSearchEmpty ? allKeys : filterKeys}
          checkedKeys={getCheckedKeysBasedOnMode()}
          // Only show nodes that match the search text
          filterTreeNode={({props: {eventKey}}) =>
            !!(searchValue && eventKey.includes(searchValue))
          }
          onCheck={keys => {
            if (withApplyFiltersButton) {
              if (keys instanceof Array) {
                // We exclude the parent items, so only leaf items are selectable
                const checkedLeafKeys = excludeParentNodes(
                  keys as string[],
                  allLeafKeys,
                );

                setPendingCheckedKeys(checkedLeafKeys as string[]);
              }
            } else if (keys instanceof Array) {
              handleCheckedKeys(keys as string[]);
            }
          }}
        />
      </ThinScrollbar>
      {withApplyFiltersButton && (
        <FlexRow style={{padding: "8px 4px 4px 4px"}}>
          <Button flat onClick={handleClear}>
            Clear
          </Button>
          <Button
            disabled={isLoadingData || areSelectionsUnchanged}
            color="primary"
            onClick={() => {
              if (pendingCheckedKeys instanceof Array) {
                handleCheckedKeys(pendingCheckedKeys);
              }
            }}
          >
            Apply Filters
          </Button>
        </FlexRow>
      )}
    </div>
  );
};

import {AdminActionRow} from "../../atoms/admin-action-row";
import {SearchResidentsContextProvider} from "./context/useFilterResidentsContext";
import {FacilityDropdownTreeSelect} from "../../atoms/facility-dropdown-tree-select";
import {AdminLayoutFullHeightAndTwoCells} from "../../atoms/admin-layout";
import {ResidentStatusFilter} from "./atoms/ResidentStatusFilter";
import {SearchResidentsField} from "./atoms/SearchResidentsField";
import {ResidentsListTable} from "./atoms/ListOfResidents";
import {DocumentTitle} from "../../atoms/document-title";
import {ButtonLink} from "../../atoms/button-link";

/**
 * Renders a list table of all residents filterable by facilities and/or a search term.
 */
export const PageAdminResidents = (): JSX.Element => {
  return (
    <AdminLayoutFullHeightAndTwoCells>
      <DocumentTitle title="Reside - Admin - Residents" />
      <SearchResidentsContextProvider>
        <AdminActionRow
          facilitySelector={<FacilityDropdownTreeSelect />}
          search={<SearchResidentsField />}
          filter={<ResidentStatusFilter />}
          actions={
            <ButtonLink
              outline={false}
              to="/admin/residents/create"
              color="primary"
            >
              New Resident
            </ButtonLink>
          }
          withCreateNewAdmissionButton={false}
        />
        <ResidentsListTable />
      </SearchResidentsContextProvider>
    </AdminLayoutFullHeightAndTwoCells>
  );
};

import React from "react";
import {Button, ModalFooterButtons} from "@reside/ui";
import {ResidentsListTable} from "../ListOfResidents";
import {Form, Formik} from "formik";
import {useLinkResidentToAdmissionContext} from "../../context/useLinkResidentToAdmissionContext";
import {useLinkResidentToAdmission} from "../../hooks/useLinkResidentToAdmission";

export const LinkAnotherResidentRadioForm = ({
  closeModal,
}: {
  closeModal: () => void;
}): JSX.Element => {
  const {toggleModal} = useLinkResidentToAdmissionContext();

  const {mutate} = useLinkResidentToAdmission(toggleModal);

  return (
    <Formik
      initialValues={{resident: ""}}
      onSubmit={values => mutate(JSON.parse(values.resident))}
    >
      <Form>
        <ResidentsListTable openDetailsOnClick={false} />
        <ModalFooterButtons style={{justifyContent: "space-between"}}>
          <Button onClick={() => closeModal()}>Cancel</Button>
          <Button type="submit" color="primary">
            Link
          </Button>
        </ModalFooterButtons>
      </Form>
    </Formik>
  );
};

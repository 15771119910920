import React from "react";
import {connect} from "react-redux";
import {AppState, select} from "../../../../store/store";
import {Spinner} from "../../../../atoms/spinner";
import {useSimilarResidents} from "./useSimilarResidents";

/** Component that defines the similar residents renderer. It's in charge of fetching similar residents. */
export const SimilarResidentsRenderer = ({
  organizationId,
}: {
  organizationId: string;
}): JSX.Element => {
  const {isFetching} = useSimilarResidents(organizationId);

  if (isFetching) return <Spinner />;
  return null;
};

const mapStateToProps = (state: AppState) => ({
  organizationId: select.adminSession.user(state).organization.id,
});

export const SimilarResidents = connect(mapStateToProps)(
  SimilarResidentsRenderer,
);

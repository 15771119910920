import {useMutation} from "react-query";
import Alert from "react-s-alert";

import {ResidentService, SearchSimilarResidents} from "../ResidentServices";

/** Query similar residents via POST request. Include search arguments in body of the request. */

export const useFetchSimilarResidents = () => {
  return useMutation(
    ["search_similar_residents"],
    async (body: SearchSimilarResidents) => {
      const {data} = await ResidentService.getSimilarResidentsViaPost(body);
      return data;
    },

    {
      onError: () => {
        Alert.error("Failed to fetch similar residents.");
      },
    },
  );
};

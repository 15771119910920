import React from "react";
import {Switch} from "react-router-dom";
import {SentryRoute as Route} from "../utils/sentryRoute";

import {PageAdmin404} from "../pages/page-admin-404";
import {PageAdminAdmissions} from "../pages/page-admin-admissions";
import {PageAdminDashboard} from "../pages/page-admin-dashboard";
import {PageAdminLogin} from "../pages/page-admin-login";
import {
  PageAdminChangePassword,
  PageAdminProfileEdit,
} from "../pages/page-admin-user-profile";
import {PageAdminSearch} from "../pages/page-admin-search";
import {PageAdminFacilitySettings} from "../pages/page-admin-facility-settings";
import {PageAdminAdmissionExperience} from "../pages/page-admin-admission-experience";
import {PageAdminActivityLog} from "../pages/page-admin-activity-log";
/* Users */
import {PageAdminUsers} from "../pages/page-admin-users";
import {
  PageAdminUserCreate,
  PageAdminUserEdit,
} from "../pages/page-admin-user-edit";

/* Residents */
import {PageAdminResidents} from "../pages/page-admin-residents";
import {ResidentProfile} from "../pages/page-admin-residents/atoms/ResidentProfile";
import {PageAdminResidentsCreate} from "../pages/page-admin-residents-forms/page-admin-residents-create/PageAdminResidentsCreate";
import {PageAdminResidentsEdit} from "../pages/page-admin-residents-forms/page-admin-residents-edit/PageAdminResidentsEdit";

/* Organizations */
import {PageAdminOrganizations} from "../pages/page-admin-organizations";
import {PageAdminOrganizationsCreate} from "../pages/page-admin-organizations-create";
import {PageAdminOrganizationsEdit} from "../pages/page-admin-organizations-edit";
import {PageAdminOrganizationImportExportUsers} from "../pages/page-admin-organizations-users";
import {PageAdminOrganizationsBranding} from "../pages/page-admin-organizations-branding";
import {PageAdminFacilityCreate} from "../pages/page-admin-facility-create";

import {AdminIsLoggedIn} from "../models/admin-session/AdminIsLoggedIn";
import {AdminIsLoggedOut} from "../models/admin-session/AdminIsLoggedOut";
import {ReportingRouter} from "./ReportingRouter";

import {HubAndSpokeRouter} from "../pages/page-admin-hub-and-spoke";

import {PageAdminContent} from "../pages/page-admin-content";

import {
  AdminIsNotClerkGuard,
  AdminIsNotResideAdminGuard,
  AdminIsOrganizationAdminGuard,
  AdminIsResideAdminGuard,
} from "../models/admin-session/AdminHasRole";
import {PageAdminPreflight} from "../pages/page-admin-preflight";
import {FeatureFlagsRouter} from "../pages/page-admin-feature-flags";
import {AdminAppRefresh} from "./AdminAppRefresh";
import {AdminApiClientConfiguration} from "./useApiClientConfiguration";
import {DashboardAdmissionsContextProvider} from "../atoms/dashboard-table/DashboardAdmissionsContext";
import {StonlyConfig} from "../atoms/stonly/StonlyConfig";
import {AutoLogoutAdmin} from "../atoms/auto-logout-admin";

/**
 * Guards must be set statically, so the component is not re-mounted, but updated.
 */
const GuardedPageAdminDashboard = AdminIsLoggedIn(
  AutoLogoutAdmin(PageAdminDashboard),
);
const GuardedPageAdminFacilitySettings = AdminIsLoggedIn(
  AdminIsNotClerkGuard(AutoLogoutAdmin(PageAdminFacilitySettings)),
);
const GuardedPageAdminActivityLog = AdminIsLoggedIn(
  AdminIsNotClerkGuard(AutoLogoutAdmin(PageAdminActivityLog)),
);
const GuardedPageAdminUserEdit = AdminIsLoggedIn(
  AutoLogoutAdmin(PageAdminUserEdit),
);
const GuardedPageAdminUserCreate = AdminIsLoggedIn(
  AutoLogoutAdmin(PageAdminUserCreate),
);
const GuardedPageAdminAdmissions = AdminIsLoggedIn(
  AutoLogoutAdmin(PageAdminAdmissions),
);
const GuardedPageAdminProfileEdit = AdminIsLoggedIn(
  AutoLogoutAdmin(PageAdminProfileEdit),
);
const GuardedPageAdminChangePassword = AdminIsLoggedIn(
  AutoLogoutAdmin(PageAdminChangePassword),
);
const GuardedPageAdminUsers = AdminIsLoggedIn(
  AdminIsNotClerkGuard(AutoLogoutAdmin(PageAdminUsers)),
);
const GuardedPageAdminSearch = AdminIsLoggedIn(
  AutoLogoutAdmin(PageAdminSearch),
);
const GuardedPageAdminAdmissionExperience = AdminIsLoggedIn(
  AutoLogoutAdmin(PageAdminAdmissionExperience),
);
const GuardedPageAdminPreflight = AdminIsLoggedIn(
  AdminIsNotResideAdminGuard(AutoLogoutAdmin(PageAdminPreflight)),
);
const GuardedPageAdminOrganizations = AdminIsLoggedIn(
  AdminIsOrganizationAdminGuard(AutoLogoutAdmin(PageAdminOrganizations)),
);
const GuardedPageAdminOrganizationsBranding = AdminIsLoggedIn(
  AdminIsOrganizationAdminGuard(
    AutoLogoutAdmin(PageAdminOrganizationsBranding),
  ),
);
const GuardedPageAdminOrganizationsCreate = AdminIsLoggedIn(
  AdminIsResideAdminGuard(AutoLogoutAdmin(PageAdminOrganizationsCreate)),
);
const GuardedPageAdminOrganizationsEdit = AdminIsLoggedIn(
  AdminIsOrganizationAdminGuard(AutoLogoutAdmin(PageAdminOrganizationsEdit)),
);
const GuardedPageAdminFacilityCreate = AdminIsLoggedIn(
  AdminIsResideAdminGuard(AutoLogoutAdmin(PageAdminFacilityCreate)),
);
const GuardedFeatureFlagsRouter = AdminIsLoggedIn(
  AdminIsResideAdminGuard(AutoLogoutAdmin(FeatureFlagsRouter)),
);
const GuardedReportingRouter = AdminIsLoggedIn(
  AutoLogoutAdmin(ReportingRouter),
);
const GuardedHSRouter = AdminIsLoggedIn(
  AdminIsResideAdminGuard(AutoLogoutAdmin(HubAndSpokeRouter)),
);

const GuardedPageAdminContent = AdminIsLoggedIn(
  AdminIsResideAdminGuard(AutoLogoutAdmin(PageAdminContent)),
);

const GuardedPageAdminResidents = AdminIsLoggedIn(
  AutoLogoutAdmin(PageAdminResidents),
);

const GuardedPageAdminResidentsProfile = AdminIsLoggedIn(
  AutoLogoutAdmin(ResidentProfile),
);
const GuardedPageAdminResidentsCreate = AdminIsLoggedIn(
  AutoLogoutAdmin(PageAdminResidentsCreate),
);
const GuardedPageAdminResidentsEdit = AdminIsLoggedIn(
  AutoLogoutAdmin(PageAdminResidentsEdit),
);

const GuardedPageAdminOrganizationImportExportUsers = AdminIsLoggedIn(
  PageAdminOrganizationImportExportUsers,
);

/**
 * Note that render is being used, so when matched route is not re-mounted (as component) but updated.
 * This is required for pages  which have local state which needs to be persisted on different routes, e.g. user edit form.
 */
const render = () => (
  <AdminAppRefresh>
    <AdminApiClientConfiguration />
    <StonlyConfig />
    <Switch>
      <Route
        exact
        path="/admin/dashboard"
        component={GuardedPageAdminDashboard}
      />
      <Route
        exact
        path="/admin/facility/:facilityId/facility-settings"
        component={GuardedPageAdminFacilitySettings}
      />
      <Route
        exact
        path="/admin/facility/:facilityId/activity-log"
        component={GuardedPageAdminActivityLog}
      />
      <Route
        exact
        path="/admin/admissions"
        component={GuardedPageAdminAdmissions}
      />
      <Route
        exact
        path="/admin/residents"
        component={GuardedPageAdminResidents}
      />
      <Route
        exact
        path="/admin/residents/create"
        component={GuardedPageAdminResidentsCreate}
      />
      <Route
        exact
        path="/admin/residents/edit/:resident_uuid"
        component={GuardedPageAdminResidentsEdit}
      />
      <Route
        exact
        path="/admin/residents/:resident_uuid"
        component={GuardedPageAdminResidentsProfile}
      />
      <Route
        path="/admin/admissions/:admissionId/(section)?/:sectionId?"
        component={GuardedPageAdminAdmissionExperience}
      />
      <Route
        exact
        path="/admin/user/:userId/profile"
        component={GuardedPageAdminProfileEdit}
      />
      <Route
        exact
        path="/admin/user/:userId/change-password"
        component={GuardedPageAdminChangePassword}
      />
      <Route exact path="/admin/users" component={GuardedPageAdminUsers} />
      <Route
        path="/admin/users/create"
        component={GuardedPageAdminUserCreate}
      />
      <Route
        path="/admin/users/:userId/edit"
        component={GuardedPageAdminUserEdit}
      />
      <Route exact path="/admin/search" component={GuardedPageAdminSearch} />
      <Route
        path="/admin/facility/:facilityId/admissions/:admissionId?"
        component={GuardedPageAdminPreflight}
      />
      <Route
        exact
        path="/admin/organizations"
        component={GuardedPageAdminOrganizations}
      />
      <Route
        exact
        path="/admin/organizations/create"
        component={GuardedPageAdminOrganizationsCreate}
      />
      <Route
        exact
        path="/admin/organizations/:organizationId/edit"
        component={GuardedPageAdminOrganizationsEdit}
      />
      <Route
        exact
        path="/admin/organizations/:organizationId/branding"
        component={GuardedPageAdminOrganizationsBranding}
      />
      <Route
        exact
        path="/admin/organizations/:organizationId/branding/:themeId"
        component={GuardedPageAdminOrganizationsBranding}
      />
      <Route
        exact
        path="/admin/organizations/:organizationId/create-facility"
        component={GuardedPageAdminFacilityCreate}
      />
      <Route
        exact
        path="/admin/organizations/:organizationId/manage-users"
        component={GuardedPageAdminOrganizationImportExportUsers}
      />
      <Route
        path="/admin/feature-flags"
        component={GuardedFeatureFlagsRouter}
      />
      <Route path="/admin/hub-and-spoke" component={GuardedHSRouter} />
      <Route path="/admin/reporting" component={GuardedReportingRouter} />
      <Route path="/admin/analytics" component={GuardedReportingRouter} />
      <Route path="/admin/content" component={GuardedPageAdminContent} />
      <Route component={PageAdmin404} />
    </Switch>
  </AdminAppRefresh>
);

export const AdminRoutes = () => (
  <DashboardAdmissionsContextProvider>
    <Switch>
      <Route
        exact
        path="/admin"
        component={AdminIsLoggedOut(() => (
          <>
            <AdminApiClientConfiguration />
            <PageAdminLogin />
          </>
        ))}
      />
      <Route path="/admin/" render={render} />
    </Switch>
  </DashboardAdmissionsContextProvider>
);

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseJsonLogicRuleForBlockField = exports.getErrorsForVisibleBlocks = exports.isFormControlBlock = exports.extractInitialFormValuesForBlock = exports.extractInitialValuesForFields = exports.extractSimpleFormFields = exports.extractFormFields = exports.getValidationConfig = exports.getValidationMessages = exports.getValidationRules = exports.getBlockName = void 0;
/* eslint-disable @typescript-eslint/ban-types */
var validator_1 = require("./validator");
var templateUtils_1 = require("./templateUtils");
var templateTypes_1 = require("./templateTypes");
var BlockFieldArray_helpers_1 = require("./BlockFieldArray.helpers");
var json_logic_1 = require("./json-logic");
var rules_1 = require("./rules");
var getBlockName = function (block) {
    return (0, exports.isFormControlBlock)(block) || (0, templateUtils_1.isMandatoryVideoNode)(block)
        ? (0, templateUtils_1.getFieldName)(block.reference)
        : (0, templateUtils_1.getFieldName)(block);
};
exports.getBlockName = getBlockName;
/**
 * Reduces list of fields to Rules record mapping field name to list of validation rules.
 */
var getValidationRules = function (fields, values) {
    return fields.reduce(function (rules, field) {
        var _a;
        if (field.type === templateTypes_1.NodeType.DEMOGRAPHICS) {
            return __assign(__assign({}, rules), rules_1.RESIDENT_DEMOGRAPHICS_RULES);
        }
        var blockName = (0, exports.getBlockName)(field);
        return __assign(__assign({}, rules), (_a = {}, _a[blockName] = field.rules
            .map(function (rule) {
            return typeof rule === "object"
                ? blockName.match(/([\w]+)\.([0-9]+)\.([\w]+)/)
                    ? (0, exports.parseJsonLogicRuleForBlockField)(rule, values, blockName)
                    : parseJsonLogicRule(rule, values)
                : rule;
        })
            .filter(function (rule) { return rule !== null; }), _a));
    }, {});
};
exports.getValidationRules = getValidationRules;
/**
 * Reduces list of fields to ErrorMessages record mapping rule&field name to error message.
 */
var getValidationMessages = function (fields) {
    return fields.reduce(function (errorMessages, block) {
        var _a;
        var fieldName = (0, exports.getBlockName)(block);
        return __assign(__assign({}, errorMessages), Object.keys((_a = block.validationMessages) !== null && _a !== void 0 ? _a : {}).reduce(function (messages, rule) {
            messages["".concat(rule, ".").concat(fieldName)] = block.validationMessages[rule];
            return messages;
        }, {}));
    }, {});
};
exports.getValidationMessages = getValidationMessages;
/**
 * Create validation function together with rules & messages for a list of blocks.
 */
var getValidationConfig = function (_a) {
    var children = _a.children, answers = _a.answers;
    var fields = (0, exports.extractFormFields)(children, undefined, answers).filter(function (field) { var _a; return ((_a = field === null || field === void 0 ? void 0 : field.rules) === null || _a === void 0 ? void 0 : _a.length) && !field.state.disabled && field.state.visible; });
    var validationRules = (0, exports.getValidationRules)(fields, answers);
    var validationMessages = (0, exports.getValidationMessages)(fields);
    var validate = function (values, rules) {
        var errors = validator_1.validator.validateAll(values, rules ? __assign(__assign({}, validationRules), rules) : validationRules, validationMessages);
        return (0, exports.getErrorsForVisibleBlocks)(errors, children, values);
    };
    return {
        validate: validate,
        validationRules: validationRules,
        validationMessages: validationMessages,
    };
};
exports.getValidationConfig = getValidationConfig;
/**
 * Extracts list of all form fields from Blocks JSON tree.
 */
var extractFormFields = function (children, prefix, answers) {
    if (prefix === void 0) { prefix = ""; }
    if (answers === void 0) { answers = {}; }
    return children === null || children === void 0 ? void 0 : children.flatMap(function (block) {
        var _a, _b, _c, _d;
        return block.type === templateTypes_1.NodeType.FIELD_ARRAY &&
            ((_a = answers[(0, exports.getBlockName)(block)]) === null || _a === void 0 ? void 0 : _a.length) > 0
            ? (_d = (_c = (_b = answers[(0, exports.getBlockName)(block)]) === null || _b === void 0 ? void 0 : _b.map(function (_, index) { return __spreadArray(__spreadArray(__spreadArray([], addCurrentBlock(block, prefix), true), descendToChoiceChildren(block, prefix, answers), true), descendToBlockChildren(block, "".concat(block.id, ".").concat(index, "."), answers), true); })) === null || _c === void 0 ? void 0 : _c.flatMap(function (block) { return block; })) === null || _d === void 0 ? void 0 : _d.filter(function (block, index, thisArg) {
                return thisArg.findIndex(function (t) { return (0, exports.getBlockName)(t) === (0, exports.getBlockName)(block); }) === index;
            })
            : __spreadArray(__spreadArray(__spreadArray([], addCurrentBlock(block, prefix), true), descendToChoiceChildren(block, prefix, answers), true), descendToBlockChildren(block, "", answers), true);
    });
};
exports.extractFormFields = extractFormFields;
/**
 * Add current block if it's a form block
 * @param block
 * @param prefix
 */
var addCurrentBlock = function (block, prefix) {
    return block.reference
        ? [
            __assign(__assign({}, block), { reference: __assign(__assign({}, block.reference), { id: "".concat(prefix).concat(block.reference.id) }) }),
        ]
        : isFormBlock(block)
            ? [block]
            : [];
};
/**
 * Descend to choice children if possible
 * @param block
 * @param prefix
 * @param answers
 */
var descendToChoiceChildren = function (block, prefix, answers) {
    var _a;
    return block.reference
        ? ((_a = block.reference.choices) !== null && _a !== void 0 ? _a : [])
            .filter(function (choice) { return choice.children; })
            .flatMap(function (choice) {
            return (0, exports.extractFormFields)(choice.children, prefix, answers);
        })
        : [];
};
/**
 * Descend to block children if possible
 * @param block
 * @param prefix
 * @param answers
 */
var descendToBlockChildren = function (block, prefix, answers) {
    return block.children
        ? (0, exports.extractFormFields)(block.children, prefix, answers)
        : // Include list blocks
            block.items
                ? (0, exports.extractFormFields)(block.items, prefix, answers)
                : [];
};
/**
 * Similar to 'extractFormFields' without extracting the fields from FieldArray children.
 */
var extractSimpleFormFields = function (children) {
    return children.flatMap(function (block) {
        var _a;
        return __spreadArray(__spreadArray(__spreadArray([], (isFormBlock(block) ? [block] : []), true), (block.reference
            ? ((_a = block.reference.choices) !== null && _a !== void 0 ? _a : [])
                .filter(function (choice) { return choice.children; })
                .flatMap(function (choice) { return (0, exports.extractSimpleFormFields)(choice.children); })
            : []), true), (block.children &&
            block.type !== templateTypes_1.NodeType.FIELD_ARRAY &&
            block.type !== templateTypes_1.NodeType.MANDATORY_VIDEO
            ? (0, exports.extractSimpleFormFields)(block.children)
            : // Include list blocks
                block.items
                    ? (0, exports.extractSimpleFormFields)(block.items)
                    : []), true);
    });
};
exports.extractSimpleFormFields = extractSimpleFormFields;
/**
 * Picks values for form fields, if value is not defined, supplies defaults.
 */
var extractInitialValuesForFields = function (fields, values) {
    return fields.reduce(function (initialValues, field) {
        var _a;
        var fieldName = (0, exports.getBlockName)(field);
        if (!fieldName) {
            return initialValues;
        }
        if (field.type === templateTypes_1.NodeType.DEMOGRAPHICS) {
            initialValues[fieldName] = values[fieldName] || {
                resident_firstName: "",
                resident_lastName: "",
                resident_gender: "",
                resident_dateOfBirth: "",
                similar_resident: "NEW_RESIDENT",
            };
        }
        else if (field.type === templateTypes_1.NodeType.FIELD_ARRAY ||
            ((_a = field.reference) === null || _a === void 0 ? void 0 : _a.type) === templateTypes_1.QuestionType.CHECKBOX) {
            initialValues[fieldName] = values[fieldName] || [];
        }
        else {
            initialValues[fieldName] = values[fieldName] || "";
        }
        return initialValues;
    }, {});
};
exports.extractInitialValuesForFields = extractInitialValuesForFields;
var extractInitialFormValuesForBlock = function (_a) {
    var children = _a.children, _b = _a.values, values = _b === void 0 ? {} : _b;
    var fields = (0, exports.extractSimpleFormFields)(children).filter(isFormBlock);
    return (0, exports.extractInitialValuesForFields)(fields, values);
};
exports.extractInitialFormValuesForBlock = extractInitialFormValuesForBlock;
/**
 * Return true when block is field array, form control, mandatory video or demographics.
 */
var isFormBlock = function (block) {
    return block.type === templateTypes_1.NodeType.FORM_CONTROL ||
        block.type === templateTypes_1.NodeType.FIELD_ARRAY ||
        block.type === templateTypes_1.NodeType.MANDATORY_VIDEO ||
        block.type === templateTypes_1.NodeType.DEMOGRAPHICS;
};
var isFormControlBlock = function (block) {
    return block.type === templateTypes_1.NodeType.FORM_CONTROL;
};
exports.isFormControlBlock = isFormControlBlock;
/**
 * From the set of errors pick only those which are for visible form fields.
 * Purpose of this helper is to remove false-positive errors for FieldArray rules
 * as some as some conditional fields might be visible in one item and hidden in another...
 * @param errors - all form errors
 * @param blocks - current tree of blocks
 * @param values - current form values
 */
var getErrorsForVisibleBlocks = function (errors, blocks, values) {
    if (values === void 0) { values = {}; }
    var fields = (0, exports.extractSimpleFormFields)(blocks);
    var fieldArrayFields = fields.flatMap(function (field) {
        return field.type === templateTypes_1.NodeType.FIELD_ARRAY
            ? (0, exports.extractSimpleFormFields)((0, BlockFieldArray_helpers_1.explodeFieldArray)(field, values[(0, templateUtils_1.getFieldName)(field)], values))
            : [];
    });
    return __spreadArray(__spreadArray([], fields, true), fieldArrayFields, true).filter(function (block) { return block.state.visible; })
        .reduce(function (visibleErrors, block) {
        var fieldName = (0, exports.getBlockName)(block);
        Object.keys(errors).forEach(function (key) {
            if (key.startsWith("".concat(fieldName, "."))) {
                visibleErrors[key] = errors[key];
            }
        });
        if (errors[fieldName]) {
            visibleErrors[fieldName] = errors[fieldName];
        }
        return visibleErrors;
    }, {});
};
exports.getErrorsForVisibleBlocks = getErrorsForVisibleBlocks;
var parseJsonLogicRule = function (rule, values) {
    var ruleName = Object.keys(rule)[0];
    return json_logic_1.jsonLogic.apply(rule[ruleName], values) ? ruleName : null;
};
var parseJsonLogicRuleForBlockField = function (rule, values, fieldName) {
    var ruleName = Object.keys(rule)[0];
    return json_logic_1.jsonLogic.apply(JSON.parse(JSON.stringify(rule[ruleName]).replace(/\.\*\./g, ".".concat(fieldName.split(".")[1], "."))), values)
        ? ruleName
        : null;
};
exports.parseJsonLogicRuleForBlockField = parseJsonLogicRuleForBlockField;

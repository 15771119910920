import React from "react";
import {Box} from "@reside/ui";
import {useParams} from "react-router";
import {useFetchResident} from "../../page-admin-residents/hooks/useFetchResident";
import {AdminLayoutFullHeightAndTwoCells} from "../../../atoms/admin-layout";
import {AdminActionRow} from "../../../atoms/admin-action-row";
import {DocumentTitle} from "../../../atoms/document-title";
import {useEditResident} from "../hooks/useEditResident";
import {ResidentFormik} from "../atoms/ResidentFormik";
import {Spinner} from "../../../atoms/spinner";

export const PageAdminResidentsEdit = (): JSX.Element => {
  const {resident_uuid} = useParams<{resident_uuid: string}>();

  const {
    data: resident,
    isLoading,
    remove: clearStaleResident,
  } = useFetchResident(resident_uuid);
  const {mutate: editResident, isLoading: isEditing} = useEditResident(
    resident?.id,
  );

  const isArchived = resident?.is_archived;

  return (
    <AdminLayoutFullHeightAndTwoCells>
      {isLoading || isEditing ? (
        <Spinner backdrop />
      ) : (
        // Control position of form buttons
        <Box position="relative">
          <DocumentTitle title="Reside - Admin - Edit Resident" />

          <AdminActionRow
            title="Edit Resident"
            withCreateNewAdmissionButton={false}
          />
          <ResidentFormik
            edit
            initialValues={{
              first_name: resident.first_name ?? "",
              middle_name: resident.middle_name ?? "",
              last_name: resident.last_name ?? "",
              date_of_birth: resident.date_of_birth ?? "",
              gender: resident.gender ?? "",
              phone_number: resident.phone_number ?? "",
              email: resident.email ?? "",
              ssn: resident.ssn ?? "",
              status: resident.status,
            }}
            handleSubmit={args => {
              editResident(args);
              clearStaleResident();
            }}
            isArchived={isArchived}
            isPccResident={Boolean(resident.pcc_patient_id)}
          />
        </Box>
      )}
    </AdminLayoutFullHeightAndTwoCells>
  );
};

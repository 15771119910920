import {useEffect} from "react";
import {FormikValues, useFormikContext} from "formik";
import {Value} from "@reside/forms";
import {useSimilarResidentsContext} from "../../context/SimilarResidentsContext";
import {usePreflightRouteParams} from "../../route";

export const useSimilarResidents = (organizationId: string) => {
  const {admissionId} = usePreflightRouteParams();
  const isEditing = typeof admissionId === "string";
  const {fetching, isPointClickCareEnabled} = useSimilarResidentsContext();

  const {initialValues, values} = useFormikContext<
    FormikValues & {similar_resident: Value}
  >();

  const similarResidentSearchTriggerDependencies = [
    values.resident_demographics.resident_firstName,
    values.resident_demographics.resident_lastName,
    values.resident_demographics.resident_gender,
    values.resident_demographics.resident_dateOfBirth,
  ];

  const hasAllDemographicsFields =
    initialValues.resident_demographics &&
    Object.values(initialValues.resident_demographics).every(Boolean);

  useEffect(
    () => {
      if (
        (isEditing && isPointClickCareEnabled) ||
        (isEditing && hasAllDemographicsFields)
      ) {
        return;
      }

      if (
        similarResidentSearchTriggerDependencies.every(e => Boolean(e)) &&
        new Date(
          values.resident_demographics.resident_dateOfBirth,
        ).getFullYear() > 1900
      ) {
        fetching.fetchSimilarResidents({
          organization_id: organizationId,
          first_name: values.resident_demographics.resident_firstName,
          last_name: values.resident_demographics.resident_lastName,
          gender: values.resident_demographics.resident_gender,
          date_of_birth: values.resident_demographics.resident_dateOfBirth as
            | string
            | Date,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    similarResidentSearchTriggerDependencies,
  );

  return {
    isFetching: fetching.isFetchingSimilarResidents,
  };
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Children, Fragment } from "react";
import { cx } from "@emotion/css";
import { Text } from "../text";
import "./ListTable.scss";
export var Table = function (_a) {
    var props = __rest(_a, []);
    var renderEmptyTable = function () { return (React.createElement("tbody", null,
        React.createElement("tr", null,
            React.createElement("td", { colSpan: Children.count(props.children), className: "table__no-rows-renderer" }, typeof props.noRowsMessage === "string" ? (React.createElement(Text, { fontSize: "1.25em", fontWeight: "medium", color: "gray100" }, props.noRowsMessage ? props.noRowsMessage : "No results found")) : (props.noRowsMessage))))); };
    var renderHeader = function () {
        return props.noRowsMessage ? undefined : (React.createElement("thead", null,
            React.createElement("tr", null, Children.map(props.children, function (columnElement, idx) {
                return (React.createElement("th", { key: idx, style: columnElement.props.width &&
                        (props.widthPercent
                            ? { width: "".concat(columnElement.props.width, "%") }
                            : { width: "".concat(columnElement.props.width, "px") }), "data-test-id": "header-".concat(columnElement.props.label) }, columnElement.props.label));
            }))));
    };
    var renderBody = function () { return (React.createElement("tbody", null, props.data.map(function (rowData, rowIdx) {
        var _a, _b, _c;
        return (React.createElement(Fragment, { key: rowIdx },
            React.createElement("tr", { role: "button", className: cx(props.basic
                    ? "list-table-basic__row"
                    : "list-table__row list-table__row-".concat((_a = props.getRowId) === null || _a === void 0 ? void 0 : _a.call(props, {
                        rowData: rowData,
                        rowIndex: rowIdx,
                    })), {
                    "list-table__row--clickable": !!props.onRowClick,
                }, props.activeRowIndex === rowIdx && "active", props.onHoverRow && "hoverable"), onClick: function () {
                    var row = { rowData: rowData, rowIndex: rowIdx };
                    if (props.onRowClick) {
                        props.onRowClick(props.getRowId(row), row);
                    }
                }, style: {
                    // @ts-expect-error - this is a valid style prop
                    "&:hover": {
                        backgroundColor: "red",
                    },
                }, onMouseOver: function () { var _a; return (_a = props.onHoverRow) === null || _a === void 0 ? void 0 : _a.call(props, rowIdx); }, onMouseLeave: function () { var _a; return (_a = props.onHoverRow) === null || _a === void 0 ? void 0 : _a.call(props, null); } }, Children.map(props.children, function (columnElement, cellIdx) { return (React.createElement("td", { "aria-hidden": "true", key: cellIdx, onClick: function () {
                    var row = { rowData: rowData, rowIndex: rowIdx };
                    if (props.onButtonClick) {
                        props.onButtonClick(props.getRowId(row), row);
                    }
                    if (props.onIconClick && columnElement.props.iconClick) {
                        props.onIconClick(props.getRowId(row), row);
                    }
                }, style: {
                    width: columnElement.props.width &&
                        (props.widthPercent
                            ? "".concat(columnElement.props.width, "%")
                            : "".concat(columnElement.props.width, "px")),
                    background: "transparent",
                } }, columnElement.props.cellRenderer({
                rowIndex: rowIdx,
                rowData: rowData,
            }))); })),
            !props.basic && (React.createElement(React.Fragment, null,
                React.createElement("tr", { className: "list-table__empty-row list-table__empty-row-".concat((_b = props.getRowId) === null || _b === void 0 ? void 0 : _b.call(props, {
                        rowData: rowData,
                        rowIndex: rowIdx,
                    })) },
                    React.createElement("td", { colSpan: Children.count(props.children) })),
                props.activeRowId ===
                    ((_c = props.getRowId) === null || _c === void 0 ? void 0 : _c.call(props, {
                        rowData: rowData,
                        rowIndex: rowIdx,
                    })) && (React.createElement("tr", { className: "list-table__empty-row" },
                    React.createElement("td", { colSpan: Children.count(props.children) })))))));
    }))); };
    return (React.createElement("table", { className: cx({
            "list-table": !props.basic,
            "list-table-basic": props.basic,
            "no-row-border": props.noRowBorder,
        }) },
        renderHeader(),
        renderBody(),
        props.data.length === 0 && renderEmptyTable()));
};

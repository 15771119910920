import React from "react";
import { Button } from "../../../button";
import { Icon } from "../../../icon";
export var ToggleCollapseButton = function (_a) {
    var isOpen = _a.isOpen, toggleOpen = _a.toggleOpen;
    return (React.createElement(Button, { onClick: function () { return toggleOpen(!isOpen); }, type: "link", style: {
            marginBottom: "24px",
            marginTop: "16px",
        } },
        React.createElement(Icon, { name: isOpen ? "up" : "down" })));
};

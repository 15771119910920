import React, {useMemo, useCallback} from "react";
import {ResidentStatus} from "../types";
import {useFilterResidentsContext} from "../context/useFilterResidentsContext";
import {DropdownTreeSelect} from "../../../atoms/dropdown-tree-select";
import {ResidentStatusSelectorWrapper} from "./styles";
import {LOCAL_STORAGE_STORED_STATUSES_KEY, TREE_DATA} from "./constants";

export const ResidentStatusFilter = (): JSX.Element => {
  const {selectedStatus, setSelectedStatus} = useFilterResidentsContext();

  // Get stored statuses
  const currentSessionStatuses = useMemo(() => {
    const stored = localStorage.getItem(LOCAL_STORAGE_STORED_STATUSES_KEY);
    return stored ? JSON.parse(stored) : selectedStatus;
  }, [selectedStatus]);

  // Memoize the check handler
  const handleCheck = useCallback(
    (_: unknown, checkedLeafKeys: unknown) => {
      localStorage.setItem(
        LOCAL_STORAGE_STORED_STATUSES_KEY,
        JSON.stringify(checkedLeafKeys),
      );
      setSelectedStatus(checkedLeafKeys as ResidentStatus[]);
    },
    [setSelectedStatus],
  );

  return (
    <ResidentStatusSelectorWrapper>
      <DropdownTreeSelect
        withSearch={false}
        checkedKeys={currentSessionStatuses}
        triggerLabel="Status"
        onCheck={handleCheck}
        treeData={TREE_DATA}
        withApplyFiltersButton
      />
    </ResidentStatusSelectorWrapper>
  );
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { useFormikContext } from "formik";
import { css, cx } from "@emotion/css";
import { Paragraph } from "../../text";
import { useBlockComponentContext } from "../BlockComponentContext";
import { ADAPTABLE_TEXT_CLASSNAME } from "../block-slide";
export var BlockParagraph = function (_a) {
    var horizontalAlignment = _a.horizontalAlignment, translationKey = _a.translationKey, staticContent = _a.staticContent, _b = _a.staticText, staticText = _b === void 0 ? "" : _b;
    var _c = useBlockComponentContext(), Markdown = _c.Markdown, Translated = _c.Translated;
    var values = useFormikContext().values;
    return (React.createElement("div", { style: { textAlign: horizontalAlignment } },
        React.createElement(Paragraph, { className: cx(ADAPTABLE_TEXT_CLASSNAME, style), fontSize: "1.25em", lineHeight: "1.75em" },
            staticText,
            React.createElement(Translated, { translationKey: translationKey, render: function (_a) {
                    var translation = _a.translation;
                    return (React.createElement(Markdown, { values: values, source: translation, staticContent: staticContent }));
                } }))));
};
BlockParagraph.defaultProps = {
    horizontalAlignment: "left",
};
var style = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 0.625em;\n"], ["\n  margin-bottom: 0.625em;\n"])));
var templateObject_1;

import React, {ReactNode} from "react";
import {Box} from "@reside/ui";
import {Title, Value} from "./styles";

type ProfileCardNodeProps =
  | {
      /** Resident object key to be displayed as title */
      title: string;
      /** Value of that key */
      value?: string | number | Date;
      children?: never;
    }
  | {
      /** Resident object key to be displayed as title */
      title: string;
      /** Value of that key */
      value?: never;

      children?: ReactNode;
    };

/** One piece of data in resident card.*/
export const ProfileCardNode = ({
  title,
  value,
  children,
}: ProfileCardNodeProps): JSX.Element => {
  return (
    <Box>
      <Title>{title}</Title>
      {children ?? <Value>{value}</Value>}
    </Box>
  );
};

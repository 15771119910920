import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useFormikContext} from "formik";
import {useLinkResidentToAdmission} from "../../../page-admin-residents/hooks/useLinkResidentToAdmission";
import {useFetchLinkedResident} from "../../../page-admin-residents/hooks/useFetchLinkedResident";
import {NEW_RESIDENT} from "../SimilarResidents/types";
import {AdmissionDraft, FormValues, LinkedResident} from "./types";

/**
 * Hook to handle linking a resident to an admission when an admission is created
 */
export const useLinkResidentOnAdmissionCreation = (
  isAdmissionCreated: boolean,
  admissionDraft: AdmissionDraft | null,
  linkedResident: LinkedResident | null,
  isPointClickCareEnabled: boolean,
) => {
  const history = useHistory();
  const {mutate: linkAdmission} = useLinkResidentToAdmission();
  const {remove} = useFetchLinkedResident(admissionDraft?.id);
  const {values} = useFormikContext<FormValues>();

  const navigateToDashboard = (): void => {
    history.push("/admin/dashboard");
  };

  useEffect(() => {
    if (!isAdmissionCreated) {
      return remove;
    }

    const similarResident = values?.resident_demographics?.similar_resident;

    // If no resident demographics fieldset was rendered
    if (similarResident === undefined) {
      navigateToDashboard();
      return remove;
    }

    const isSimilarResidentSelected = similarResident !== NEW_RESIDENT;

    if (isSimilarResidentSelected) {
      // Use the selected similar resident or the linked resident's ID
      const residentId = similarResident || linkedResident?.id || "";

      linkAdmission({
        resident_id: residentId,
        admission_id: admissionDraft.id,
      });
      navigateToDashboard();
      return remove;
    } else if (!isPointClickCareEnabled) {
      // If no similar resident is selected and PCC is not enabled, just navigate to dashboard
      navigateToDashboard();
      return remove;
    }

    // Clean up function
    return remove;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmissionCreated, isPointClickCareEnabled]);
};

import {useEffect} from "react";
import {useFormikContext} from "formik";
import {NEW_RESIDENT, FetchStatusReturnType} from "../SimilarResidents/types";
import {FormValues} from "./types";

export const useHandleSimilarResidents = (fetching: FetchStatusReturnType) => {
  const {setFieldValue, initialValues} = useFormikContext<FormValues>();

  const {similarResidentsFound, similarResidents, isFetchingSimilarResidents} =
    fetching;

  const setSimilarResidentField = (residentId: string): void => {
    setFieldValue("resident_demographics.similar_resident", residentId);
  };

  const setToNewResident = (): void => {
    setSimilarResidentField(NEW_RESIDENT);
  };

  const setToExistingResident = (residentId: string): void => {
    setSimilarResidentField(residentId);
  };

  useEffect(() => {
    // Skip processing if no similar residents found or no initial value
    const initialSimilarResident =
      initialValues?.resident_demographics?.similar_resident;
    if (!similarResidentsFound || !initialSimilarResident) {
      return;
    }

    // Check for a perfect match first
    const perfectMatch = similarResidents.find(
      resident => resident.match === "100%",
    );

    if (perfectMatch) {
      setToExistingResident(perfectMatch.resident.id);
      return;
    }

    // Check if current resident exists in similar residents
    const currentResidentExists = similarResidents.some(
      similarResident => similarResident.resident.id === initialSimilarResident,
    );

    // Set to existing resident if found, otherwise set to new resident
    if (currentResidentExists) {
      setToExistingResident(initialSimilarResident);
    } else {
      setToNewResident();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetchingSimilarResidents,
    similarResidentsFound,
    similarResidents,
    initialValues?.resident_demographics?.similar_resident,
  ]);
};

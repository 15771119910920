import {ResidentSubStatus} from "../types";

/**
 * Helper function to determine filter flag values based on status selection
 *
 * @param activeStatus - The active status to check for (e.g., ARCHIVED)
 * @param inactiveStatus - The corresponding inactive status (e.g., NOT_ARCHIVED)
 * @param currentSessionStatuses - Array of currently selected statuses
 * @returns
 *   - true: when only activeStatus is selected
 *   - false: when only inactiveStatus is selected
 *   - null: when both or neither statuses are selected
 */
export const filterSwitch = (
  activeStatus: ResidentSubStatus,
  inactiveStatus: ResidentSubStatus,
  currentSessionStatuses: Array<string>,
) => {
  if (
    currentSessionStatuses.includes(activeStatus) &&
    currentSessionStatuses.includes(inactiveStatus)
  ) {
    return null;
  }
  if (currentSessionStatuses.includes(activeStatus)) {
    return true;
  }
  if (currentSessionStatuses.includes(inactiveStatus)) {
    return false;
  }
  return null;
};

/**
 * Helper function to determine archived filter flag
 */
export const filterSwitchArchived = (
  currentSessionStatuses: Array<string>,
  archivedStatus: ResidentSubStatus,
  notArchivedStatus: ResidentSubStatus,
) => filterSwitch(archivedStatus, notArchivedStatus, currentSessionStatuses);

/**
 * Helper function to determine waiting list filter flag
 */
export const filterSwitchWaitingList = (
  currentSessionStatuses: Array<string>,
  waitingListStatus: ResidentSubStatus,
  notWaitingListStatus: ResidentSubStatus,
) =>
  filterSwitch(waitingListStatus, notWaitingListStatus, currentSessionStatuses);

import {RESIDENT_SUB_STATUS} from "../types";

export const LOCAL_STORAGE_STORED_STATUSES_KEY = "currentSessionStatuses";

export const RESIDENT_STATUS_ITEMS = [
  {key: "NEW", title: "New"},
  {key: "CURRENT", title: "Current"},
  {key: "DISCHARGED", title: "Discharged"},
];

export const RESIDENT_ARCHIVED_SUB_STATUS_ITEMS = [
  {key: RESIDENT_SUB_STATUS.ARCHIVED, title: "Archived Residents"},
  {key: RESIDENT_SUB_STATUS.NOT_ARCHIVED, title: "Non Archived Residents"},
];

export const RESIDENT_WAITING_LIST_SUB_STATUS_ITEMS = [
  {key: RESIDENT_SUB_STATUS.WAITING_LIST, title: "Residents on Waiting List"},
  {
    key: RESIDENT_SUB_STATUS.NOT_WAITING_LIST,
    title: "Residents not on Waiting List",
  },
];

export const TREE_DATA = [
  {
    key: "resident-status",
    title: "Resident Status",
    className: "status",
    "data-title": "Resident Status",
    children: RESIDENT_STATUS_ITEMS,
  },
  {
    key: "resident-waiting-list-sub-status",
    title: "Waiting List Sub Status",
    className: "status",
    "data-title": "Waiting List",
    children: RESIDENT_WAITING_LIST_SUB_STATUS_ITEMS,
  },
  {
    key: "resident-archived-sub-status",
    title: "Archived Sub Status",
    className: "status",
    "data-title": "Archived",
    children: RESIDENT_ARCHIVED_SUB_STATUS_ITEMS,
  },
];

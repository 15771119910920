import {useFormikContext} from "formik";
import {useHistory} from "react-router";
import Alert from "react-s-alert";
import {CreateResidentDto} from "../types";

/** Handle checks and values that affect similar residents window. */
export const useHandleSimilarResidents = () => {
  /** Extract values and function */
  const {values: createFormValues} = useFormikContext<
    CreateResidentDto & {
      /**Resident that is currently selected as existing resident in form of JSON string*/
      similar_resident?: string;
    }
  >();

  const history = useHistory();

  const returnToResidentsPage = () => {
    history.push("/admin/residents");
    Alert.success("Existing user successfully selected.");
  };

  const shouldSearchSimilarResidents =
    !!createFormValues.organization_id &&
    !!createFormValues.first_name &&
    !!createFormValues.last_name &&
    !!createFormValues.date_of_birth;

  const similarResidentsSearchData = {
    organization_id: createFormValues.organization_id,
    first_name: createFormValues.first_name,
    last_name: createFormValues.last_name,
    gender: createFormValues.gender,
    date_of_birth: createFormValues.date_of_birth as string | Date,
  };

  return {
    /** When user is seleceted navigate back to residents list page and inform him about what happened with toast message.*/
    returnToResidentsPage,
    /** Check if all values need to search for similar residents are entered. Search will happen before similar residents window is shown. */
    shouldSearchSimilarResidents,
    /** Values to search similar residents by. */
    similarResidentsSearchData,
    selectedExistingResident: createFormValues?.similar_resident,
  };
};

import React, {useEffect, useState} from "react";
import {Text} from "@reside/ui";
import {format} from "date-fns";
import {ResidentAdmissionsButtonWithModal} from "../../../atoms/resident-admissions-modal/ResidentAdmissionsButtonWithModal";
import {useFetchSimilarResidents} from "../../../services/hooks/useFetchSimilarResidents";
import {useHandleSimilarResidents} from "../hooks/useHandleSimilarResidents";
import {TextCell} from "../../../atoms/list-table/SharedCells";
import {Resident, Row} from "../../page-admin-residents/types";
import {ListTable, Column} from "../../../atoms/list-table";
import {getAge} from "../../page-admin-residents/helpers";

export const SimilarResidents = ({
  isResideAdminUser,
}: {
  isResideAdminUser: boolean;
}): JSX.Element => {
  const CellRenderer = (value: string | number | Date) => (
    <TextCell>{value}</TextCell>
  );

  const {shouldSearchSimilarResidents, similarResidentsSearchData} =
    useHandleSimilarResidents();

  const {
    data: remoteResidents,
    mutate: fetchSimilarResidents,
    isLoading: isFetchingSimilarResidents,
  } = useFetchSimilarResidents();

  const [
    shouldShowSimilarResidentsWindow,
    setShouldShowSimilarResidentsWindow,
  ] = useState<boolean>(true);

  // Whenever new similar residents are sought for check if window should be shown. It should be shown only when similar residents exist.
  useEffect(() => {
    setShouldShowSimilarResidentsWindow(!!remoteResidents?.length);
    // eslint-disable-next-line
  }, [isFetchingSimilarResidents]);

  let searchDuplicatesDeps = [
    similarResidentsSearchData.first_name,
    similarResidentsSearchData.last_name,
    similarResidentsSearchData.date_of_birth,
    fetchSimilarResidents,
  ];

  if (isResideAdminUser) {
    searchDuplicatesDeps = [
      similarResidentsSearchData.organization_id,
      ...searchDuplicatesDeps,
    ];
  }

  useEffect(() => {
    // Whenever conditions are met similar residents will be searched.
    if (shouldSearchSimilarResidents) {
      fetchSimilarResidents(similarResidentsSearchData);
    }
    // eslint-disable-next-line
  }, searchDuplicatesDeps);

  if (!shouldShowSimilarResidentsWindow) return null;

  return (
    <>
      <Text fontSize={24} margin="32px 0 16px 0" as="h1">
        Similar residents have been found
      </Text>
      <Text as="p" fontSize="1rem" marginBottom={32}>
        Please check if this resident already exists:
      </Text>

      <ListTable<{resident: Resident; match: string}>
        key="12345"
        data={remoteResidents ?? []}
        getRowId={row => row.rowData.resident.id}
        noRowsMessage="No residents to display"
      >
        <Column
          cellRenderer={({rowData}: Row) => {
            const name = `${rowData.resident.first_name} ${
              rowData.resident.middle_name ? rowData.resident.middle_name : ""
            } ${rowData.resident.last_name}`;

            return CellRenderer(name);
          }}
          label="Resident"
          width={250}
        />

        <Column
          cellRenderer={({rowData}: Row) =>
            CellRenderer(rowData.resident.gender)
          }
          label="Gender"
          width={200}
        />

        <Column
          cellRenderer={({rowData}: Row) =>
            CellRenderer(
              rowData.resident.date_of_birth
                ? format(new Date(rowData.resident.date_of_birth), "MM/dd/yyyy")
                : "—",
            )
          }
          label="Date of Birth"
          width={200}
        />

        <Column
          cellRenderer={({rowData}: Row) =>
            CellRenderer(getAge(rowData.resident.date_of_birth).toString())
          }
          label="Age"
          width={100}
        />

        <Column
          cellRenderer={({rowData}: Row) => CellRenderer(rowData.resident.ssn)}
          label="Social Security Number"
          width={200}
        />

        <Column
          cellRenderer={({rowData}: Row) => (
            <ResidentAdmissionsButtonWithModal rowData={rowData} />
          )}
          width={50}
        />
      </ListTable>
    </>
  );
};

import React from "react";
import { Text } from "../../../text";
import { Tag } from "../../../tag";
import { NEW_RESIDENT } from "./types";
import { parseDate } from "./utils/helpers";
export var MatchedResidentInfo = function (_a) {
    var _b, _c, _d, _e;
    var firstName = _a.firstName, lastName = _a.lastName, gender = _a.gender, dateOfBirth = _a.dateOfBirth, similar_resident = _a.similar_resident, matchedResident = _a.matchedResident;
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, null,
            ((_b = matchedResident === null || matchedResident === void 0 ? void 0 : matchedResident.resident) === null || _b === void 0 ? void 0 : _b.first_name) || firstName || "First name",
            ",",
            " ",
            ((_c = matchedResident === null || matchedResident === void 0 ? void 0 : matchedResident.resident) === null || _c === void 0 ? void 0 : _c.last_name) || lastName || "last name",
            ",",
            " ",
            ((_d = matchedResident === null || matchedResident === void 0 ? void 0 : matchedResident.resident) === null || _d === void 0 ? void 0 : _d.gender) || gender || "gender",
            ",",
            " ",
            dateOfBirth
                ? parseDate(((_e = matchedResident === null || matchedResident === void 0 ? void 0 : matchedResident.resident) === null || _e === void 0 ? void 0 : _e.date_of_birth) || dateOfBirth, "date of birth")
                : "date of birth"),
        " ",
        "\u00A0",
        React.createElement(Tag, { color: "green100" }, similar_resident === NEW_RESIDENT ? "New" : "Linked")));
};

import React from "react";
import {UserDto} from "@reside/reside-api-app";
import {format} from "date-fns";
import {isEmpty} from "lodash";

import {isResideAdmin} from "../../../utils/helpers/users";
import {ProfileCardNode} from "./ProfileCardNode";
import {getAge, parseAPIDate} from "../helpers";
import {CardNodeResident} from "./CardNodeResident";
import {ResidentCardWrapp} from "./styles";
import {Resident} from "../types";

export type ResidentForProfileCard = Pick<
  Resident,
  | "resident_id"
  | "first_name"
  | "last_name"
  | "date_of_birth"
  | "gender"
  | "created_by"
  | "organization"
  | "pcc_patient_id"
> & {age?: number};

export const ProfileCard = ({
  resident,
  user,
}: {
  resident: ResidentForProfileCard;
  user: UserDto;
}): JSX.Element => {
  const emptyValue = "—";
  const residentDateOfBirth = !isEmpty(resident?.date_of_birth)
    ? `${format(parseAPIDate(resident.date_of_birth), "MM/dd/yyyy")}`
    : emptyValue;

  return (
    <ResidentCardWrapp>
      <ProfileCardNode title="Resident">
        <CardNodeResident
          firstName={resident?.first_name}
          lastName={resident?.last_name}
          organizationName={resident?.organization.name}
          isResideAdmin={isResideAdmin(user)}
        />
      </ProfileCardNode>

      <ProfileCardNode title="Resident ID" value={resident?.resident_id} />
      <ProfileCardNode title="EHR ID" value={resident?.pcc_patient_id || "—"} />

      <ProfileCardNode title="Date of birth" value={residentDateOfBirth} />
      <ProfileCardNode
        title="Age"
        value={getAge(new Date(resident?.date_of_birth))}
      />
      <ProfileCardNode
        title="Gender"
        value={!isEmpty(resident?.gender) ? resident.gender : emptyValue}
      />
      <ProfileCardNode title="Created by" value={resident?.created_by} />
    </ResidentCardWrapp>
  );
};

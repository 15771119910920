import React from "react";
import { useFormikContext } from "formik";
import { NewResidentBanner, Radio } from "./styles";
import { Column } from "../../../table/Column";
import { CellRenderer } from "./CellRenderer";
import { Table } from "../../../table/Table";
import { NEW_RESIDENT } from "./types";
import { parseDate } from "./utils/helpers";
/**
 * NewResident component displays a table containing the demographics of a resident that is currently being added.
 *
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {boolean} [props.hasSimilarResidents=false] - A flag indicating if there are similar residents.
 * @returns {JSX.Element} A table with the resident's demographic details, including first name, last name, gender, and date of birth.
 *
 * @remarks
 * To maintain consistency in style with the similar residents list, a table is used here as well, even though it contains only one row. It will show radio button only if there are similar residents so that selection can be made. It will also show a blue banner with information of possible duplicates.
 *
 */
export var NewResident = function (_a) {
    var _b = _a.hasSimilarResidents, hasSimilarResidents = _b === void 0 ? false : _b;
    var resident_demographics = useFormikContext().values.resident_demographics;
    var NEW_RESIDENT_TABLE_ITEM = {
        match: NEW_RESIDENT,
        resident: {
            first_name: resident_demographics.resident_firstName,
            last_name: resident_demographics.resident_lastName,
            date_of_birth: resident_demographics.resident_dateOfBirth,
            gender: resident_demographics.resident_gender,
            id: NEW_RESIDENT,
        },
    };
    return (React.createElement(NewResidentBanner, { hasSimilarResidents: hasSimilarResidents },
        React.createElement(Table, { data: [NEW_RESIDENT_TABLE_ITEM], getRowId: function (_a) {
                var rowData = _a.rowData;
                return rowData.resident.id;
            } },
            React.createElement(Column, { cellRenderer: function (_a) {
                    var rowData = _a.rowData;
                    return (React.createElement(Radio, { id: "similar_resident_radio", onClick: function (e) {
                            e.stopPropagation();
                        }, name: "resident_demographics.similar_resident", type: "radio", value: rowData.resident.id }));
                }, width: 100 }),
            React.createElement(Column, { cellRenderer: function (_a) {
                    var rowData = _a.rowData;
                    return CellRenderer({
                        label: "First name",
                        value: rowData.resident.first_name || "—",
                    }, resident_demographics.resident_firstName ===
                        rowData.resident.first_name);
                }, label: "First Name", width: 250 }),
            React.createElement(Column, { cellRenderer: function (_a) {
                    var rowData = _a.rowData;
                    return CellRenderer({
                        label: "Last name",
                        value: rowData.resident.last_name || "—",
                    }, resident_demographics.resident_lastName ===
                        rowData.resident.last_name);
                }, label: "Last Name", width: 250 }),
            React.createElement(Column, { cellRenderer: function (_a) {
                    var rowData = _a.rowData;
                    return CellRenderer({ label: "Gender", value: rowData.resident.gender || "—" }, resident_demographics.resident_gender === rowData.resident.gender);
                }, label: "Gender", width: 150 }),
            React.createElement(Column, { cellRenderer: function (_a) {
                    var rowData = _a.rowData;
                    return CellRenderer({
                        label: "Date of birth",
                        value: rowData.resident.date_of_birth
                            ? parseDate(rowData.resident.date_of_birth)
                            : "—",
                    }, resident_demographics.resident_dateOfBirth ===
                        rowData.resident.date_of_birth);
                }, label: "Date of Birth", width: 150 }))));
};

import {SearchSimilarResidents} from "../../../../services/ResidentServices";
import {Resident} from "../../../page-admin-residents/types";

export type FetchStatusReturnType = {
  similarResidents: {
    resident: Resident;
    match: string;
  }[];
  fetchSimilarResidents: (body: SearchSimilarResidents) => void;
  isFetchingSimilarResidents: boolean;
  clearSimilarResidentsData: () => void;
  similarResidentsFound: boolean;
};

export const NEW_RESIDENT = "NEW_RESIDENT";

import {useQuery} from "react-query";
import {logErrorToSentry} from "../../../utils/sentry";
import {
  ResidentService,
  SORT_RESIDENTS_BY,
} from "../../../services/ResidentServices";
import {useEffect, useState} from "react";
import {useDebouncedPrimitiveValue} from "../../../hooks/useDebouncedPrimitiveValue";
import {PAGE_SIZE, DEBOUNCE_SEARCH_BY} from "../consts";
import {RESIDENT_SUB_STATUS, ResidentStatus, ResidentSubStatus} from "../types";
import {
  filterSwitchArchived,
  filterSwitchWaitingList,
} from "../helpers/filterHelpers";

/** Query residents via POST request. Include search arguments in body of the request. */

export const useFetchResidentsViaPost = (
  searchTerm?: string,
  facility_ids?: string[],
  selectedStatus?: Array<ResidentStatus | ResidentSubStatus>,
) => {
  // By default, residents should be sorted by name. Clicking table header updates the state which triggers new request.
  const [sortBy, setSortBy] =
    useState<(typeof SORT_RESIDENTS_BY)[number]>("name");

  // Default sort direction is ascending
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

  // Debounce search term to avoid request overload
  const debouncedSearchTerm = useDebouncedPrimitiveValue(
    searchTerm,
    DEBOUNCE_SEARCH_BY,
  );

  const currentSessionStatuses: Array<ResidentStatus | ResidentSubStatus> =
    JSON.parse(localStorage.getItem("currentSessionStatuses")) ||
    selectedStatus;

  // Use the helper functions instead of inline functions
  const getCurrentArchiveState = () =>
    filterSwitchArchived(
      currentSessionStatuses,
      RESIDENT_SUB_STATUS.ARCHIVED,
      RESIDENT_SUB_STATUS.NOT_ARCHIVED,
    );

  const getCurrentWaitingListState = () =>
    filterSwitchWaitingList(
      currentSessionStatuses,
      RESIDENT_SUB_STATUS.WAITING_LIST,
      RESIDENT_SUB_STATUS.NOT_WAITING_LIST,
    );

  const {
    data: remoteResidents,
    isFetching: isFetchingResidents,
    refetch,
  } = useQuery(
    ["search_residents"],
    async () => {
      const {data} = await ResidentService.getResidentsViaPost({
        facility_ids: facility_ids ?? [],
        search: searchTerm,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        // Archived and not archived are not included in the filter array but calculated as flags from checked statuses
        resident_status: currentSessionStatuses.filter(
          status =>
            status !== RESIDENT_SUB_STATUS.WAITING_LIST &&
            status !== RESIDENT_SUB_STATUS.ARCHIVED &&
            status !== RESIDENT_SUB_STATUS.NOT_ARCHIVED &&
            status !== RESIDENT_SUB_STATUS.NOT_WAITING_LIST,
        ),
        is_archived: getCurrentArchiveState(),
        waiting_list: getCurrentWaitingListState(),
      });
      return data;
    },

    {
      cacheTime: 0,
      refetchOnMount: false,
      onError: (err: Response) => {
        logErrorToSentry(err, {
          queryKey: "search_residents",
          facilities: facility_ids,
        });
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [sortBy, sortDirection, pageSize, facility_ids, selectedStatus, refetch]);

  // When field is cleared trigger search for residents again
  useEffect(() => {
    if (searchTerm.length > 1 || searchTerm.length === 0) {
      setPageSize(PAGE_SIZE);
      if (pageSize === 30) refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, refetch]);

  return {
    residents: remoteResidents?.data,
    totalCount: remoteResidents?.total_count,
    isFetchingResidents,
    sort: (
      sortBy: (typeof SORT_RESIDENTS_BY)[number],
      sortDirection: "asc" | "desc",
    ) => {
      setSortBy(sortBy);
      setSortDirection(sortDirection);
    },
    loadMoreResidents: () => setPageSize(prev => prev + PAGE_SIZE),
    pageSize,
  };
};

import {useEffect, useState} from "react";
import {extractInitialFormValuesForBlock, Values} from "@reside/forms";
import {PreflightSlideBlock} from "../../types";

type FormStateOfSlidesType = {
  [key: string]: Values;
};

/** Each slide in a preflight is a form of it's own. When you navigate through slides it is imposible to keep track of "dirty" form state because it is always reseting to false. This hook takes care of that by combining all inputs on all slides into one state used as reference. */
export const useIsPreflightDirty = ({
  slides,
  values,
  currentSlide,
}: {
  slides: PreflightSlideBlock[];
  values: Values;
  currentSlide: PreflightSlideBlock;
}) => {
  const [
    /** Initial state of all the preflight slides together. */
    trueInitialState,
    setTrueInitialState,
  ] = useState<FormStateOfSlidesType>(null);

  const [
    /** Set this state to keep the track of changes on every change so that it can be compared to trueInitialState.  */
    dirtyFormState,
    setDirtyFormState,
  ] = useState<FormStateOfSlidesType>(null);

  /** When change has happend and that change is making form dirty fill this array with id of the slide that is changed. */
  const [dirtySlides, setDirtySlides] = useState([]);

  useEffect(() => {
    if (slides.length)
      slides.forEach(slide => {
        /** Preflight works in such way: User makes a change on a slide, goes to another slide and comes back to the first one - initialState of the form is reinitialized and has values that he entered in a change. By setting this variable on first render we can have a reference of what the true initial state of all the preflight slides together is. */
        setTrueInitialState(prevState => {
          return {
            ...prevState,
            [slide.id]: extractInitialFormValuesForBlock({
              children: slide.children,
              values,
            }),
          };
        });
      });
    // eslint-disable-next-line
  }, [slides.length]);

  useEffect(() => {
    if (
      dirtyFormState?.[currentSlide?.id] && // Dirty form state won't exist until form is touched
      JSON.stringify(dirtyFormState[currentSlide?.id]) !==
        JSON.stringify(trueInitialState[currentSlide?.id])
    ) {
      setDirtySlides(
        // When change has happend and that change is making form dirty fill this array with id of the slide that is changed.
        prevState => {
          return !prevState.includes(currentSlide.id)
            ? [...prevState, currentSlide.id]
            : prevState;
        },
      );
    } else {
      setDirtySlides(prevState => {
        // When form is returned to the initial state remove that slide from array
        return prevState.filter(e => e !== currentSlide.id);
      });
    }
    // eslint-disable-next-line
  }, [dirtyFormState]);

  /** When preflight is saved, start tracking changes based on new ones. */
  const resetDirtyFormOnSaveSuccess = () => {
    setDirtyFormState(null);
    setDirtySlides([]);
  };

  return {
    trueInitialState,
    dirtyFormState,
    setDirtyFormState,
    dirtySlides,
    setDirtySlides,
    resetDirtyFormOnSaveSuccess,
  };
};

import React, {ReactNode} from "react";
import {Box} from "@reside/ui";

import {Space} from "../space";
import {NotResideAdmin} from "../admin-roles";
import {AdminPageTitle} from "../admin-layout/AdminPageTitle";
import {FacilityDropdownMenu} from "../facility-dropdown-menu";

import "./AdminActionRow.scss";

/** Action row adjustable to suit the needs of your page. You can pass it custom components for searching, filtering or facility selector. */
export const AdminActionRow = ({
  title = null,
  filter = null,
  search = null,
  facilitySelector = null,
  actions = null,
  withCreateNewAdmissionButton = true,
}: {
  title?: ReactNode;
  filter?: ReactNode;
  search?: ReactNode;
  actions?: ReactNode;
  facilitySelector?: ReactNode;
  withCreateNewAdmissionButton?: boolean;
}) => (
  <Box
    flexDirection="row"
    alignItems="center"
    flexShrink={0}
    className="adminactionrow-0-1"
  >
    <Box flexDirection="row" flex="2" alignItems="center">
      {facilitySelector}
      {title && <AdminPageTitle>{title}</AdminPageTitle>}
    </Box>
    <Space>
      {filter}
      {search}
      {actions}
      {withCreateNewAdmissionButton && (
        <NotResideAdmin>
          <FacilityDropdownMenu />
        </NotResideAdmin>
      )}
    </Space>
  </Box>
);

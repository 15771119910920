import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import {useFetchSimilarResidents} from "../../../services/hooks/useFetchSimilarResidents";
import {PccPatient} from "../../../services/PccService";
import {SearchSimilarResidents} from "../../../services/ResidentServices";
import {FetchStatusReturnType} from "../atoms/SimilarResidents/types";

type SimilarResidentsContextReturnType = {
  fetching: FetchStatusReturnType;
  isPointClickCareEnabled: boolean;
  pccResident: PccPatient;
  setPccResident: Dispatch<SetStateAction<PccPatient>>;
  openDemographics: boolean;
  setOpenDemographics: Dispatch<SetStateAction<boolean>>;
};

const SimilarResidentsContext =
  createContext<SimilarResidentsContextReturnType>(undefined);

export const SimilarResidentsContextProvider = ({
  children,
  isPointClickCareEnabled,
}: {
  children: JSX.Element;
  isPointClickCareEnabled: boolean;
}) => {
  const [pccResident, setPccResident] = useState<PccPatient>();

  const [openDemographics, setOpenDemographics] = useState(true);

  const {
    data: similarResidents,
    mutate,
    isLoading: isFetchingSimilarResidents,
    reset: clearSimilarResidentsData,
  } = useFetchSimilarResidents();

  const fetchSimilarResidents = (body: SearchSimilarResidents) => mutate(body);

  useEffect(() => {
    if (similarResidents?.length) {
      const hasTotalMatch = similarResidents[0].match === "100%";

      if (hasTotalMatch) {
        setOpenDemographics(false); // Close the demographics section when a 100% match is found
      } else if (!hasTotalMatch) {
        setOpenDemographics(true); // Reopen the demographics section when a 100% match is not found on repeated search
      }
    } else if (similarResidents?.length === 0) {
      setOpenDemographics(false); // Close the demographics section when no match is found
    }
  }, [isFetchingSimilarResidents, similarResidents]);

  return (
    <SimilarResidentsContext.Provider
      value={{
        fetching: {
          similarResidents,
          fetchSimilarResidents,
          isFetchingSimilarResidents,
          clearSimilarResidentsData,
          similarResidentsFound: Boolean(similarResidents?.length),
        },
        isPointClickCareEnabled,
        setPccResident,
        pccResident,
        openDemographics,
        setOpenDemographics,
      }}
    >
      {children}
    </SimilarResidentsContext.Provider>
  );
};

export const useSimilarResidentsContext =
  (): SimilarResidentsContextReturnType => {
    const context = useContext(SimilarResidentsContext);

    if (context === undefined) {
      throw new Error(
        "useSimilarResidentsContext must be used within SimilarResidentsContextProvider",
      );
    }

    return context;
  };

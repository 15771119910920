"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESIDENT_DEMOGRAPHICS_RULES = void 0;
exports.RESIDENT_DEMOGRAPHICS_RULES = {
    resident_demographics: {
        resident_firstName: ["required"],
        resident_lastName: ["required"],
        resident_gender: ["required"],
        resident_dateOfBirth: ["required"],
    },
};

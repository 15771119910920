import React from "react";
import {Button, Icon, Text} from "@reside/ui";
import {useHistory} from "react-router";

export const BackButton = (): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <Button
        type="link"
        style={{marginTop: "5px"}}
        onClick={() => history.push("/admin/residents")}
      >
        <Icon name="left" size={15} color="primary" />
      </Button>
      <Text>Resident Profile</Text>
    </>
  );
};

import React from "react";
import {useParams} from "react-router";
import {LinkResidentToAdmissionModalProvider} from "../../context/useLinkResidentToAdmissionContext";
import {LinkAnotherResidentModal} from ".";
import {useFetchResident} from "../../hooks/useFetchResident";
import {
  AdmissionsDeleteAdmissionModal,
  AdmissionsDeleteModalProvider,
} from "../../../../atoms/admin-admissions-table/admission-delete-modal";
import {
  AdmissionsRestoreAdmissionModal,
  AdmissionsRestoreModalProvider,
} from "../../../../atoms/admin-admissions-table/admission-restore-modal";
import {
  ChangeHistoryModal,
  ChangeHistoryModalProvider,
} from "../../../../atoms/dashboard-table/atoms/change-history-modal";

export const ModalsProviders = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const {resident_uuid} = useParams<{resident_uuid: string}>();

  const {refetch} = useFetchResident(resident_uuid);

  return (
    <LinkResidentToAdmissionModalProvider>
      <AdmissionsDeleteModalProvider>
        <AdmissionsRestoreModalProvider>
          <ChangeHistoryModalProvider>
            <LinkAnotherResidentModal />
            <AdmissionsDeleteAdmissionModal onDeleted={() => refetch()} />
            <AdmissionsRestoreAdmissionModal onRestored={() => refetch()} />
            <ChangeHistoryModal />
            {children}
          </ChangeHistoryModalProvider>
        </AdmissionsRestoreModalProvider>
      </AdmissionsDeleteModalProvider>
    </LinkResidentToAdmissionModalProvider>
  );
};

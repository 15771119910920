import React from "react";
import {connect} from "react-redux";

import {useFacilitySettingsQuery} from "../page-admin-facility-settings/hooks/useFacilitySettingsQuery";
import {usePointClickCareStatus} from "../../hooks/usePointClickCareStatus";
import {useTemplateVersionQuery} from "../../hooks/useTemplateVersionQuery";
import {usePreflightTemplateQuery} from "../../hooks/useTemplateQuery";
import {Facilities} from "../../models/types/AdmissionForm";
import {AdminLayout} from "../../atoms/admin-layout";
import {AppState, select} from "../../store/store";
import {PreflightLayout} from "./PreflightLayout";
import {usePreflightRouteParams} from "./route";
import {Spinner} from "../../atoms/spinner";

export const PageAdminPreflightRenderer = ({
  facilities,
}: {
  facilities: Facilities;
}) => {
  const {admissionId, facilityId} = usePreflightRouteParams();

  const {isFetching: isFetchingFacilitySettings, data: facilitySettings} =
    useFacilitySettingsQuery({facilityId});

  const {data: {version, admission} = {}, isFetching: isFetchingVersion} =
    useTemplateVersionQuery({
      admissionId,
      facilityId,
    });

  const {data: template, isFetching: isFetchingTemplate} =
    usePreflightTemplateQuery({versionId: version?.id});

  const {isPccEnabled} = usePointClickCareStatus(facilities, facilityId);

  return (
    <AdminLayout>
      {isFetchingFacilitySettings || isFetchingVersion || isFetchingTemplate ? (
        <Spinner />
      ) : (
        <PreflightLayout
          facilityId={facilityId}
          admission={admission}
          version={version}
          facilitySettings={facilitySettings}
          admissionId={admissionId}
          template={template}
          isPointClickCareEnabled={isPccEnabled}
        />
      )}
    </AdminLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  facilities: select.adminSession.facilities(state),
});

export const PageAdminPreflight = connect(mapStateToProps)(
  PageAdminPreflightRenderer,
);

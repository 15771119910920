import {isValid, parse} from "date-fns";
import {ResidentStatus, ResolvedResidentStatus} from "./types";

/**
 *
 * @param birthDate
 * @returns Age of person with given date of birth
 */
export const getAge = (birthDate: Date | string): number =>
  Math.floor(
    (new Date().getTime() - new Date(birthDate).getTime()) / 3.15576e10,
  );

export const maskSsnString = (ssn: string) => {
  return `*****${ssn?.replace(/-/g, "").slice(-4)}`;
};

export const resolveResidentStatus = (
  status: ResidentStatus,
): ResolvedResidentStatus => {
  if (status === "NEW") {
    return {
      isActive: true,
      tagText: "New",
      tagColor: "green100",
    };
  } else if (status === "CURRENT") {
    return {
      isActive: true,
      tagText: "Current",
      tagColor: "primary",
    };
  } else if (status === "DISCHARGED") {
    return {
      isActive: true,
      tagText: "Discharged",
      tagColor: "deepOrange100",
    };
  } else {
    return {
      isActive: null,
      tagText: null,
      tagColor: null,
    };
  }
};

export const parseAPIDate = (apiDateStrings: string | number | Date) => {
  const parsedDate = parse(
    apiDateStrings as string,
    "yyyy-MM-dd'T'00:00:00",
    new Date(),
  );
  return isValid(parsedDate)
    ? parsedDate
    : parse(apiDateStrings as string, "yyyy-MM-dd", new Date());
};

import {isUndefined, isEqual, get, omitBy, mapValues, pickBy} from "lodash";
import {Admission, AnswerOfobject} from "@reside/reside-api-admission";
import {DATE_FORMAT} from "@reside/ui/dist/date-picker/date";
import {QueryVariableMap, QueryVariables} from "@reside/ui";
import {format, parseISO} from "date-fns";
import {
  Blocks,
  getBlockName,
  getValidationConfig,
  NodeType,
  QuestionType,
  extractInitialValuesForFields,
  extractSimpleFormFields,
  Values,
  Value,
  PreflightCoreVariables,
  Rules,
  validator,
  ErrorMessages,
  getErrorsForVisibleBlocks,
  QuestionReferences,
  isQueryChoicesNode,
} from "@reside/forms";

import {PayerType, PccPatientDetail} from "../../../../services/PccService";
import {GenderMapIds} from "../../../../utils/constants/user";
import {PreflightSlideBlock} from "../../types";
import {
  AdmissionAnswerObject,
  AdmissionAnswerObjects,
  AdmissionStatusEnum,
} from "../../../../services/AdmissionsService";
import {
  AdvancedDirectiveStatus,
  OtherPayerTypeId,
  PrefligtAnswerId,
  PrimaryPayerTypeId,
  STATES,
  UsStates,
} from "../../../../utils/constants";

export const getInitialState = ({
  facilityId,
  id,
  admissionId,
  answers = {},
  status = Admission.StatusEnum.PRE_FLIGHT,
  created = Math.floor(Date.now() / 1000),
  updated = Math.floor(Date.now() / 1000),
  templateId = "",
  templateName = "",
  ...rest
}: Admission & {
  admissionId: string;
}): Admission => ({
  id: id || admissionId,
  facilityId,
  answers,
  status,
  created,
  updated,
  templateId,
  templateName,
  ...rest,
});

const checkIsRuleRequired = (
  rules: string | Rules | (string | Validator.TypeCheckingRule)[],
) =>
  rules === "required" ||
  isEqual(rules, ["required"]) ||
  rules === "radio" ||
  isEqual(rules, ["radio"]);

/**
 * Make all the rules optional.
 * When there is single required rule, omit the field from rules, otherwise if field has multiple rules, filter out the required.
 */
export const removeRequiredRule = (validationRules: Rules) =>
  mapValues(
    omitBy(validationRules, rules => {
      if (!rules.length) {
        omitBy(rules, value => checkIsRuleRequired(value));
      }

      return checkIsRuleRequired(rules);
    }),
    rules => {
      if (!rules.length) {
        // When rules is nested object go in and remove required or radio rules.
        rules = mapValues(
          omitBy(rules, value => {
            return checkIsRuleRequired(value);
          }),
          rules => {
            return Array.isArray(rules)
              ? rules.filter(value => value !== "required" && value !== "radio")
              : rules;
          },
        );
      }

      return Array.isArray(rules)
        ? rules.filter(value => value !== "required" && value !== "radio")
        : rules;
    },
  );

export const createCoreRules = (validationRules: Rules) => ({
  ...validationRules,
  ...Object.values(PreflightCoreVariables).reduce<Rules>(
    (rules, fieldName: string) => {
      const fieldRules = validationRules[fieldName];

      rules[fieldName] = [
        ...(typeof fieldRules === "string"
          ? [fieldRules]
          : Array.isArray(fieldRules)
          ? fieldRules
          : []),
        "required",
      ];

      return rules;
    },
    {},
  ),
});

export const validateCore = ({
  values,
  validationRules,
  validationMessages,
  children,
}: {
  values: Values;
  validationRules: Rules;
  validationMessages: ErrorMessages;
  children: ReadonlyArray<Blocks>;
}) => {
  const rulesWithoutRequired = removeRequiredRule(validationRules);
  const coreValidationRules = createCoreRules(rulesWithoutRequired);

  const errors = validator.validateAll(
    values,
    coreValidationRules,
    validationMessages,
  );

  return getErrorsForVisibleBlocks(errors, children, values);
};

const validateMultiSlideForm = ({
  slides,
  validationFn,
}: {
  slides: PreflightSlideBlock[];
  validationFn: (slide: PreflightSlideBlock) => ErrorMessages;
}): {
  slides: PreflightSlideBlock[];
  isValid: boolean;
} => {
  let isValid = true;

  return {
    slides: slides.map(slide => {
      if (slide.state.visible) {
        const errors = validationFn(slide) as any;

        const errorCount = Object.keys(errors).length;

        if (errorCount > 0) {
          isValid = false;
        }

        return {...slide, state: {...slide.state, errors}};
      }

      return {...slide, state: {...slide.state, errors: {}}};
    }),
    isValid,
  };
};

/**
 * Ignore required rule. Used when saving or editing preflight.
 */
export const coreValidation = ({
  answers,
  slides,
}: {
  answers: Values;
  slides: PreflightSlideBlock[];
}) => {
  const validationFn = (slide: PreflightSlideBlock) => {
    const {validationMessages, validationRules} = getValidationConfig({
      children: slide.children,
      answers,
    });

    return validateCore({
      values: answers,
      children: slide.children,
      validationMessages,
      validationRules,
    });
  };

  return validateMultiSlideForm({slides, validationFn});
};

export const fullValidation = ({
  answers,
  slides,
}: {
  answers: Values;
  slides: PreflightSlideBlock[];
}) => {
  const validationFn = (slide: PreflightSlideBlock) => {
    const {validate} = getValidationConfig({
      children: slide.children,
      answers,
    });

    return validate(answers);
  };

  return validateMultiSlideForm({slides, validationFn});
};

const getQuestionType = (
  type: NodeType,
  reference: QuestionReferences,
): Exclude<QuestionType, QuestionType.TEXTAREA> => {
  if (type === NodeType.FIELD_ARRAY) {
    return QuestionType.LIST;
  }

  if (!reference) {
    return QuestionType.TEXT;
  }

  if (isQueryChoicesNode(reference)) {
    return QuestionType.OBJECT;
  }

  if (reference.type === QuestionType.TEXTAREA) {
    return QuestionType.TEXT;
  }

  return QuestionType[reference.type] || QuestionType.TEXT;
};

const mapFieldToAnswer = ({
  id,
  type,
  reference,
  value,
}: {
  id?: string;
  type?: NodeType;
  reference?: QuestionReferences;
  value?: Value;
}): AdmissionAnswerObject => {
  return {
    value,
    questionId: reference?.id ?? id,
    questionType: getQuestionType(type, reference),
    preFlight: true,
    fileReference: false,
  };
};

export const mapPreflightToAnswers = <V = Values>({
  children,
  values,
  queryVariables = {},
}: {
  children: ReadonlyArray<Blocks>;
  values?: V;
  queryVariables?: QueryVariables;
}): AdmissionAnswerObjects => {
  const preflightFields = extractSimpleFormFields(children)
    .filter(({state, reference, type}: any) => {
      return (
        type === NodeType.DEMOGRAPHICS ||
        (state &&
          (state.disabled === false ||
            (state.disabled === true && state.preFlight === true) ||
            (state.disabled === true && reference?.computation)) &&
          state.visible === true)
      );
    })
    .filter((field: any) => {
      return (
        field.type === NodeType.DEMOGRAPHICS ||
        field.reference ||
        field.children
      );
    });

  const initialValues = extractInitialValuesForFields(
    preflightFields,
    values as any as Values,
  );

  return preflightFields.reduce<Record<string, any>>(
    (admissionAnswers, field: any) => {
      const id = getBlockName(field);
      const value = initialValues[id];

      if (!id) {
        return admissionAnswers;
      }

      if (!value) {
        admissionAnswers[id] = mapFieldToAnswer({
          id: field.id,
          reference: field.reference,
          type: field.type,
          value: null,
        });

        return admissionAnswers;
      }

      if (field.reference?.query) {
        const queryValues = get(queryVariables, field.reference.query.path, []);

        admissionAnswers[id] = mapFieldToAnswer({
          id: field.id,
          reference: field.reference,
          type: field.type,
          value: queryValues.find((item: any) => item.id === value) || {},
        });

        return admissionAnswers;
      }

      admissionAnswers[id] = mapFieldToAnswer({
        id: field.id,
        reference: field.reference,
        type: field.type,
        value: initialValues[id],
      });

      return admissionAnswers;
    },
    {},
  );
};

export const mapAdmissionAnswersToValues = (
  answers: Admission["answers"] = {},
) =>
  Object.entries(answers)
    .filter(([, answer]) => answer.preFlight === true)
    .reduce<Values>((values, [name, answer]) => {
      if (answer.questionType === AnswerOfobject.QuestionTypeEnum.OBJECT) {
        values[name] = answer.value.id;

        return values;
      }

      values[name] = answer.value;

      return values;
    }, {});

const formatPreflightDate = (date: string) =>
  format(parseISO(date), DATE_FORMAT.ISO_DATE);

type pccPatientDetailToPreflightAnswersResult = {
  preflightAnswers: Record<string, Value>;
  preflightIds: ReadonlyArray<string>;
  preflightTags: ReadonlyArray<string>;
};

export const pccPatientDetailToPreflightAnswers = (
  pccPatientDetail: PccPatientDetail,
): pccPatientDetailToPreflightAnswersResult => {
  const mappedData = omitBy(
    {
      resident_firstName: {
        value: pccPatientDetail.firstName,
        id: PrefligtAnswerId.fistName,
      },
      resident_lastName: {
        value: pccPatientDetail.lastName,
        id: PrefligtAnswerId.lastName,
      },
      resident_gender: {
        value: GenderMapIds[pccPatientDetail.gender],
        id: PrefligtAnswerId.gender,
      },
      resident_dateOfBirth: {
        value: formatPreflightDate(pccPatientDetail.birthDate),
        id: PrefligtAnswerId.birthDate,
      },
      resident_ssn: {
        value: pccPatientDetail.socialBeneficiaryIdentifier,
        id: PrefligtAnswerId.ssn,
      },
      resident_phone: {
        value: pccPatientDetail.homePhone,
        id: PrefligtAnswerId.phone,
      },
      resident_email: {
        value: pccPatientDetail.email,
        id: PrefligtAnswerId.email,
      },
      resident_address_street: {
        value: pccPatientDetail.previousAddress?.addressLine1,
        id: PrefligtAnswerId.addressStreet,
      },
      resident_address_number: {
        value: pccPatientDetail.previousAddress?.addressLine2,
        id: PrefligtAnswerId.addressNumber,
      },
      resident_address_city: {
        value: pccPatientDetail.previousAddress?.city,
        id: PrefligtAnswerId.addressCity,
      },
      resident_address_state: {
        value:
          pccPatientDetail.previousAddress?.state &&
          // NOTE in PCC the previousAddress.state can be any string , in case the state hasn't been found in our state enum use null instead
          STATES.find(
            state =>
              state.label === UsStates[pccPatientDetail.previousAddress.state],
          )?.id,
        id: PrefligtAnswerId.addressState,
      },
      resident_address_zipcode: {
        value: pccPatientDetail.previousAddress?.postalCode,
        id: PrefligtAnswerId.addressZipCode,
      },
      preflight_dateOfAdmission: {
        value:
          pccPatientDetail.admissionDate &&
          formatPreflightDate(pccPatientDetail.admissionDate),
        id: PrefligtAnswerId.dateOfAdmission,
      },
      preflight_roomNumber: {
        value: pccPatientDetail.roomId && `${pccPatientDetail.roomId}`,
        id: PrefligtAnswerId.roomNumber,
      },
      preflight_advancedDirective: {
        value: pccPatientDetail.advanceDirectivesConsents
          ? AdvancedDirectiveStatus.YES
          : AdvancedDirectiveStatus.NO,
        id: PrefligtAnswerId.AdvancedDirective,
      },
      preflight_payerSource: {
        value: pccPatientDetail.primaryPayerSource?.payerType
          ? PrimaryPayerTypeId[pccPatientDetail.primaryPayerSource.payerType]
          : undefined,
        id: PrefligtAnswerId.primaryPayerSource,
      },
      preflight_daysRemaining: {
        value: pccPatientDetail.primaryPayerSource
          ? pccPatientDetail.primaryPayerSource.remainingDays
          : undefined,
        id: PrefligtAnswerId.daysRemaining,
      },
      preflight_secondaryPayerSource: {
        value: pccPatientDetail.secondaryPayerSource?.payerType
          ? [OtherPayerTypeId[pccPatientDetail.secondaryPayerSource.payerType]]
          : undefined,
        id: PrefligtAnswerId.secondaryPayerSource,
      },
      preflight_secondaryDaysRemaining: {
        value: pccPatientDetail.secondaryPayerSource
          ? pccPatientDetail.secondaryPayerSource.remainingDays
          : undefined,
        id: PrefligtAnswerId.secondaryDaysRemaining,
      },
      preflight_medicareAHicNumber: {
        value:
          pccPatientDetail.primaryPayerSource &&
          pccPatientDetail.primaryPayerSource.payerType === PayerType.medicareA
            ? pccPatientDetail.primaryPayerSource.id
            : undefined,
        id: PrefligtAnswerId.medicareAHicNumber,
      },
      preflight_secondaryMedicareAHicNumber: {
        value:
          pccPatientDetail.secondaryPayerSource &&
          pccPatientDetail.secondaryPayerSource.payerType ===
            PayerType.medicareA
            ? pccPatientDetail.secondaryPayerSource.id
            : undefined,
        id: PrefligtAnswerId.secondaryMedicareAHicNumber,
      },

      preflight_medicaidNumber: {
        value:
          pccPatientDetail.primaryPayerSource &&
          pccPatientDetail.primaryPayerSource.payerType === PayerType.medicaid
            ? pccPatientDetail.primaryPayerSource.id
            : undefined,
        id: PrefligtAnswerId.medicaidNumber,
      },
      preflight_secondaryMedicaidNumber: {
        value:
          pccPatientDetail.secondaryPayerSource &&
          pccPatientDetail.secondaryPayerSource.payerType === PayerType.medicaid
            ? pccPatientDetail.secondaryPayerSource.id
            : undefined,
        id: PrefligtAnswerId.secondaryMedicaidNumber,
      },
      preflight_dateOfPccDataCapture: {
        value: new Date().toISOString(),
        id: PrefligtAnswerId.dateOfPccDataCapture,
      },
    },
    data => isUndefined(data.value),
  );

  const preflightTags = Object.keys(mappedData);
  const preflightIds = preflightTags.map(key => mappedData[key].id);

  const preflightAnswers = Object.fromEntries(
    Object.entries(mappedData).map(([key, value]) => [key, value.value]),
  );

  return {
    preflightAnswers,
    preflightIds,
    preflightTags,
  };
};

// we want to have sent only answers from PreFlight form
export const getPreFlightPatchAdmissionPayload = ({
  answers,
  slides,
  queryVariables,
}: {
  answers: Values;
  slides: ReadonlyArray<PreflightSlideBlock>;
  queryVariables?: QueryVariableMap;
}) => ({
  answers: {
    ...mapPreflightToAnswers({
      children: slides.flatMap(({children}) => children),
      values: answers,
      queryVariables,
    }),
  },
});

export const getPreFlightCreateAdmissionPayload = ({
  admissionDraft,
  slides,
  status,
  answers,
  queryVariables,
}: {
  admissionDraft: Admission;
  slides: ReadonlyArray<PreflightSlideBlock>;
  status?: AdmissionStatusEnum;
  answers: Values;
  queryVariables?: QueryVariableMap;
}) => {
  return {
    ...admissionDraft,
    status: status || admissionDraft.status,
    answers: {
      ...admissionDraft.answers,
      ...mapPreflightToAnswers({
        children: slides.flatMap(({children}) => children),
        values: answers,
        queryVariables,
      }),
      ...pickBy(admissionDraft.answers, value => !value.preFlight),
    },
  };
};

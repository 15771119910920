import React from "react";
import { Button } from "../../../button";
import { NEW_RESIDENT } from "./types";
import { Text } from "../../../text";
import { NavLink } from "../../../navigation";
import { theme } from "../../../theme";
export var ManageResidentButton = function (_a) {
    var linkedResidentId = _a.linkedResidentId;
    var invalidateResidentNavigation = !linkedResidentId || linkedResidentId === NEW_RESIDENT;
    return (React.createElement(Button, { type: "link", style: {
            cursor: invalidateResidentNavigation ? "not-allowed" : "auto",
            marginBottom: "8px",
            marginTop: "16px",
        }, disabled: invalidateResidentNavigation }, invalidateResidentNavigation ? (React.createElement(Text, { style: {
            cursor: "not-allowed",
            color: theme.color.gray100,
            textDecoration: "underline",
        } }, "Manage Resident")) : (React.createElement(NavLink, { style: {
            color: theme.color.primary,
            textDecoration: "underline",
        }, to: "/admin/residents/".concat(linkedResidentId) }, "Manage resident"))));
};

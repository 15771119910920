import React from "react";
import {NavLink} from "@reside/ui";
import {NavigationProps} from "./types";

/** Tabs always visible in navigation bar next to one another. */
export const Tabs = ({role, displayReportingTab}: NavigationProps) => {
  return (
    <>
      <NavLink exact to="/admin/dashboard" data-test-id="dashboardLink">
        Dashboard
      </NavLink>

      <NavLink to="/admin/admissions" data-test-id="admissionsLink">
        Admissions
      </NavLink>

      <NavLink to="/admin/residents" data-test-id="residentsLink">
        Residents
      </NavLink>

      {displayReportingTab && (
        <NavLink to="/admin/reporting" data-test-id="reportingLink">
          Reporting
        </NavLink>
      )}
    </>
  );
};

import {useMutation} from "react-query";
import {Admission} from "@reside/reside-api-admission";
import Alert from "react-s-alert";
import {ResidentService} from "../../../services/ResidentServices";
import {AdmissionFromSearch} from "../../../services/AdmissionsService";

export const useLinkResidentToAdmission = (
  toggleModal?: (admission?: Admission | AdmissionFromSearch) => void,
) => {
  return useMutation(
    ({
      admission_id,
      resident_id,
    }: {
      admission_id: string;
      resident_id: string;
    }) => ResidentService.linkResidentToAdmission({admission_id, resident_id}),
    {
      onError: () => {
        Alert.error("Failed to link Admission.");
      },
    },
  );
};

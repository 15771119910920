import {useState} from "react";
import {scrollToTop} from "../../../../hooks/useScrollToTopOnHistoryChange";

type State = Readonly<{
  activeSlideIndex: number;
}>;

export const useSlideUi = ({slideCount}: {slideCount: number}) => {
  const [uiState, setUiState] = useState<State>({
    activeSlideIndex: 0,
  });

  const setActiveSlideIndex = (activeSlideIndex: number) => {
    scrollToTop();
    setUiState(state => ({
      ...state,
      activeSlideIndex,
    }));
  };

  const isLastSlide = uiState.activeSlideIndex === slideCount - 1;

  const goToNextSlide = () => {
    if (!isLastSlide) {
      scrollToTop();
      setActiveSlideIndex(uiState.activeSlideIndex + 1);
    }
  };

  return {
    isLastSlide,
    uiState,
    uiActions: {
      goToNextSlide,
      setActiveSlideIndex,
    },
  };
};

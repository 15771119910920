import React, {useMemo} from "react";
import {BlockComponentContextType, FormikAutoSaveValues} from "@reside/ui";
import {extractInitialFormValuesForBlock} from "@reside/forms";
import {Formik, Form} from "formik";
import {mapValues} from "lodash";

import {produceAnswersWithDistinctValueForArrayField} from "./produceAnswersWithDistinctValueForArrayField";
import {SaveBeforeLeavePrompt} from "../../../atoms/save-before-leave-prompt/SaveBeforeLeavePrompt";
import {SimilarResidents} from "./SimilarResidents/SimilarResidents";
import {DocumentTitle} from "../../../atoms/document-title";
import {PreflightCard, ValidationBlock} from "./styles";
import {validateCore} from "../utils/helpers/helpers";
import {PreFlightFieldset} from "./PreFlightFieldset";
import {usePreflightRouteParams} from "../route";
import {t} from "../../../atoms/i18n-provider";
import {PreFlightFormProps} from "./types";

const distinctArrayFieldTag = "emergencyContact";
const distinctFieldTag = "primaryContact";

export const PreFlightForm = ({
  children,
  admissionDraft,
  isLastSlide,
  uiActions,
  currentSlide,
  answers,
  setAnswers,
  getAnswers,
  validate,
  hasErrorsVisible,
  getIsCoreValidation,
  useCoreValidation,
  validationMessages,
  validationRules,
  queryVariables,
  isPatchingDraft,
  isSavingDraft,
  isPatchingAdmission,
  isSendingToResident,
  createDraft,
  patchDraft,
  createAdmission,
  patchAdmission,
  produceAnswers = produceAnswersWithDistinctValueForArrayField(
    distinctArrayFieldTag,
    distinctFieldTag,
  ),
  dirtySlides,
  setDirtyFormState,
  currentSlideIndex,
  hasSetInProgress,
}: PreFlightFormProps) => {
  const {admissionId} = usePreflightRouteParams();

  const isEditing = typeof admissionId === "string";

  // The reinitialize should happened only when
  // - the slide was changed
  // - pcc does data capture
  const initialValues = useMemo(
    () =>
      extractInitialFormValuesForBlock({
        children: currentSlide.children,
        values: answers,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSlide.id, answers.preflight_dateOfPccDataCapture],
  );

  return (
    <Formik
      key={currentSlide.id}
      validateOnMount
      initialValues={{
        ...initialValues,
      }}
      enableReinitialize
      initialErrors={currentSlide.state.errors}
      initialTouched={
        hasErrorsVisible && mapValues(currentSlide.state.errors, () => true)
      }
      validate={values =>
        useCoreValidation
          ? validateCore({
              values,
              validationMessages,
              validationRules,
              children: currentSlide?.children ?? [],
            })
          : validate(values)
      }
      onSubmit={() => {}}
    >
      {({values}) => {
        return (
          <>
            <DocumentTitle
              title={`Reside - Edit Pre-Flight - ${t(
                currentSlide.title.translationKey,
              )}`}
            />
            <SaveBeforeLeavePrompt
              nonFormikDirty={Boolean(dirtySlides.length)}
            />
            <FormikAutoSaveValues
              /* computed fields in preflight must have debounce: on */
              onChangeValues={async values => {
                setDirtyFormState(prevState => {
                  const {...remainingValues} = values;
                  return {
                    ...prevState,
                    [currentSlide.id]: remainingValues, // Spread remaining values
                  };
                });
                // we need to await answers, answers in props are lagging recently updated values
                const answers = await getAnswers();

                const nextAnswers = produceAnswers(answers, {
                  ...values,
                });

                const useCoreValidation = await getIsCoreValidation();

                setAnswers(nextAnswers, useCoreValidation);
              }}
            />
            <Form>
              <PreflightCard>
                <PreFlightFieldset
                  admissionDraft={admissionDraft}
                  isLastSlide={isLastSlide}
                  uiActions={uiActions}
                  queryVariables={queryVariables}
                  isPatchingAdmission={isPatchingAdmission}
                  isPatchingDraft={isPatchingDraft}
                  isSavingDraft={isSavingDraft}
                  isSendingToResident={isSendingToResident}
                  isEditing={isEditing}
                  createDraft={createDraft}
                  patchDraft={patchDraft}
                  createAdmission={createAdmission}
                  patchAdmission={patchAdmission}
                  currentSlide={currentSlide}
                  currentSlideIndex={currentSlideIndex}
                  hasSetInProgress={hasSetInProgress}
                />
              </PreflightCard>

              {
                // If resident demographics are present in template, show similar residents functionality
                Boolean(values?.resident_demographics) && <SimilarResidents />
              }
            </Form>
            {children}
          </>
        );
      }}
    </Formik>
  );
};

export const preflightBlockComponents: BlockComponentContextType = {
  ValidationBlock,
};

import React, {
  createContext,
  useContext,
  FunctionComponent,
  useState,
  useMemo,
} from "react";
import {ResidentStatus} from "../types";

const context = createContext<{
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  selectedStatus: ResidentStatus[];
  setSelectedStatus: (value: ResidentStatus[]) => void;
}>({
  searchTerm: "",
  setSearchTerm: (value: string) => {},
  selectedStatus: null,
  setSelectedStatus: (value: ResidentStatus[]) => {},
});

export const useFilterResidentsContext = () => useContext(context);

export const SearchResidentsContextProvider: FunctionComponent = ({
  children,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<ResidentStatus[]>([]);

  const contextValue = useMemo(
    () => ({
      searchTerm,
      setSearchTerm,
      selectedStatus,
      setSelectedStatus,
    }),
    [searchTerm, selectedStatus],
  );

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

import {useState} from "react";
import {H1, Modal, ModalContent, Tooltip} from "@reside/ui";
import {connect} from "react-redux";

import {ResidentStatusTag} from "../../pages/page-admin-residents/atoms/ResidentStatusTag";
import {useFetchResident} from "../../pages/page-admin-residents/hooks/useFetchResident";
import {ProfileCard} from "../../pages/page-admin-residents/atoms/ProfileCard";
import {Resident} from "../../pages/page-admin-residents/types";
import {AdminPageTitle} from "../admin-layout/AdminPageTitle";
import {ResidentAdmissions} from "./ResidentAdmissions";
import {UserDto} from "@reside/reside-api-app";
import {AppState, select} from "../../store";
import {BackdropSpinner} from "../spinner";
import {IconButton} from "../icon-button";
import {AdmissionIcon} from "./styles";

/**
 * ResidentAdmissionsButtonWithModal component renders a button that triggers a modal to display resident admissions.
 */
export const ResidentAdmissionsButtonWithModalRenderer = ({
  rowData,
  user,
}: {
  rowData: {resident: Resident; match: string};
  user: UserDto;
}) => {
  /**
   * The `activeModalResidentId` state keeps track of the currently active modal by storing the resident's ID.
   * This helps ensure that only one modal is open at a time and allows for easy identification of the resident whose admissions are being displayed.
   */
  const [activeModalResidentId, setActiveModalResidentId] = useState("");

  const {
    data: resident,
    isLoading,
    remove: clearStaleResident,
  } = useFetchResident(activeModalResidentId);

  return (
    <div>
      <Tooltip content="See admissions for this resident">
        <IconButton
          iconName="options-file-upload"
          frameless
          onClick={() => {
            setActiveModalResidentId(rowData.resident.id);
          }}
          Icon={AdmissionIcon}
        />
      </Tooltip>

      <Modal
        fullScreen
        closeButtonPosition="right"
        size="large"
        isOpen={activeModalResidentId === rowData?.resident.id}
        onRequestClose={() => {
          setActiveModalResidentId("");
          clearStaleResident();
        }}
      >
        {() => (
          <ModalContent style={{height: "80"}}>
            <AdminPageTitle
              style={{
                display: "flex",
                alignItems: "center",
                gap: 20,
                maxHeight: "80px",
              }}
            >
              <H1>Resident Profile</H1>
              <ResidentStatusTag status={resident?.status} />
            </AdminPageTitle>

            <BackdropSpinner active={isLoading}>
              <ProfileCard resident={resident} user={user} />
              <ResidentAdmissions
                admissions={rowData?.resident.admissions}
                showMoreOptions={false}
                isRedirectToAdmissionAllowed={false}
              />
            </BackdropSpinner>
          </ModalContent>
        )}
      </Modal>
    </div>
  );
};

const mapState = (state: AppState) => ({
  user: select.adminSession.user(state),
});

export const ResidentAdmissionsButtonWithModal = connect(mapState)(
  ResidentAdmissionsButtonWithModalRenderer,
);

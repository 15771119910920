import {useQuery} from "react-query";
import {ResidentService} from "../../../services/ResidentServices";
import {Resident} from "../types";

export const useFetchLinkedResident = (
  admissionId: string,
): {
  data?: Resident;
  /** Remove both id and resident query to prevent stale data. */
  remove: () => void;
  isFetching: boolean;
} => {
  const {
    data: isResidentLinkedData,
    remove: clearId,
    isFetching: isFetchingId,
  } = useQuery(
    ["linked_resident_id"],
    async () => {
      const {data} = await ResidentService.getLinkedResidentId(admissionId);

      return data;
    },
    {
      enabled: !!admissionId,
    },
  );

  const {
    data,
    remove: clearResident,
    isFetching: isFetchingResident,
  } = useQuery(
    ["linked_resident"],
    async () => {
      const {data} = await ResidentService.getOneResident(
        isResidentLinkedData?.resident_id,
      );

      return data;
    },
    {
      enabled: !!isResidentLinkedData?.resident_id,
    },
  );

  return {
    data,
    remove: () => {
      clearId();
      clearResident();
    },
    isFetching: isFetchingId || isFetchingResident,
  };
};

import {styled, Icon} from "@reside/ui";

export const HouseIcon = styled(Icon)`
  margin-right: 6px;
`;

export const UserIcon = styled(Icon)`
  margin-right: 6px;
`;

export const FacilityIconsWrapper = styled.div`
  line-height: 20px;
`;

export const AdmissionIcon = styled(Icon)`
  margin-right: -16px;
`;

import React from "react";
import { useFormikContext } from "formik";
import { Column } from "../../../table/Column";
import { CellRenderer } from "./CellRenderer";
import { Table } from "../../../table/Table";
import { H2 } from "../../../text";
import { NotMatchingResidentBanner, Radio } from "./styles";
import { parseDate } from "./utils/helpers";
/**
 * SimilarResidents component displays a table of residents with similar demographics to identify possible duplicates.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {Array<Object>} props.items - An array of similar resident objects to display in the table.
 * @param {Function} props.modal - A modal component to handle resident file actions.
 * @returns {JSX.Element | null} A table displaying the similar residents' demographic details or null if there are no similar residents.
 *
 * @remarks
 * This table allows users to identify residents with similar information. It provides a list of potential duplicates based on demographic matches.
 */
export var SimilarResidents = function (_a) {
    var similarResidents = _a.items, ResidentFilesModal = _a.modal;
    var resident_demographics = useFormikContext().values.resident_demographics;
    if (!similarResidents.length)
        return null;
    var isSelectedResidentFullMatch = function (id) {
        var resident = similarResidents.find(function (item) { return item.resident.id === id; });
        return resident ? resident.match === "100%" : false;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(H2, { style: { marginTop: "32px", marginBottom: "16px" } }, "Possible Duplicates"),
        React.createElement(NotMatchingResidentBanner, { id: isSelectedResidentFullMatch(resident_demographics.similar_resident)
                ? ""
                : resident_demographics.similar_resident },
            React.createElement(Table, { data: similarResidents, activeRowId: isSelectedResidentFullMatch(resident_demographics.similar_resident)
                    ? ""
                    : resident_demographics.similar_resident, getRowId: function (_a) {
                    var rowData = _a.rowData;
                    return rowData.resident.id;
                } },
                React.createElement(Column, { cellRenderer: function (_a) {
                        var rowData = _a.rowData;
                        return (React.createElement(Radio, { onClick: function (e) {
                                e.stopPropagation();
                            }, name: "resident_demographics.similar_resident", type: "radio", value: rowData.resident.id }));
                    }, width: 100 }),
                React.createElement(Column, { cellRenderer: function (_a) {
                        var rowData = _a.rowData;
                        return CellRenderer({
                            label: "First name",
                            value: rowData.resident.first_name || "—",
                            id: rowData.resident.resident_id,
                        }, resident_demographics.resident_firstName ===
                            rowData.resident.first_name);
                    }, label: "First Name", width: 250 }),
                React.createElement(Column, { cellRenderer: function (_a) {
                        var rowData = _a.rowData;
                        return CellRenderer({
                            label: "Last name",
                            value: rowData.resident.last_name || "—",
                        }, resident_demographics.resident_lastName ===
                            rowData.resident.last_name);
                    }, label: "Last Name", width: 250 }),
                React.createElement(Column, { cellRenderer: function (_a) {
                        var rowData = _a.rowData;
                        return CellRenderer({ label: "Gender", value: rowData.resident.gender || "—" }, resident_demographics.resident_gender ===
                            rowData.resident.gender);
                    }, label: "Gender", width: 150 }),
                React.createElement(Column, { cellRenderer: function (_a) {
                        var rowData = _a.rowData;
                        return CellRenderer({
                            label: "Date of birth",
                            value: rowData.resident.date_of_birth
                                ? parseDate(rowData.resident.date_of_birth)
                                : "—",
                        }, resident_demographics.resident_dateOfBirth ===
                            rowData.resident.date_of_birth);
                    }, label: "Date of Birth", width: 150 }),
                React.createElement(Column, { cellRenderer: function (_a) {
                        var rowData = _a.rowData, rowIndex = _a.rowIndex;
                        return (React.createElement("div", { style: {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "16px",
                            } }, ResidentFilesModal({
                            rowData: rowData,
                            rowIndex: rowIndex,
                        })));
                    }, width: 150 })))));
};

import {Admission} from "@reside/reside-api-admission";
import {Color} from "@reside/ui";

export enum RESIDENT_SUB_STATUS {
  ARCHIVED = "ARCHIVED",
  WAITING_LIST = "WAITING_LIST",
  NOT_ARCHIVED = "NOT_ARCHIVED",
  NOT_WAITING_LIST = "NOT_WAITING_LIST",
}

export type ResidentSubStatus = `${RESIDENT_SUB_STATUS}`;

export enum RESIDENT_STATUS {
  CURRENT = "CURRENT",
  NEW = "NEW",
  DISCHARGED = "DISCHARGED",
}

export type ResidentStatus = `${RESIDENT_STATUS}`;

export type ResolvedResidentStatus = {
  isActive: boolean;
  tagText: string;
  tagColor: Color;
};

type Facility = {
  id: string;
  name: string;
};

export type ResidentAdmissionJson = {
  completion_percentage: number;
  signature_date: string;
};

export type ResidentAdmission = {
  id: string;
  date_of_admission: string;
  facility: Facility;
  status: Admission.StatusEnum;
  admission: ResidentAdmissionJson;
  code: number;
  created: string;
  created_by: string;
  updated: string;
  updated_by: string | null;
};

export type ResidentOrganization = {
  id: string;
  name: string;
};

export type Resident = {
  id: string;
  resident_id: string;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  date_of_birth: string;
  gender: string;
  phone_number: string;
  email: string | null;
  ssn: string;
  status: ResidentStatus;
  created: string;
  created_by: string;
  organization_id?: string;
  organization?: ResidentOrganization;
  admissions: ResidentAdmission[];
  pcc_patient_id?: string;
  is_archived?: boolean;
  waiting_list?: boolean;
};

export type Row = {
  rowData: {resident: Resident; match: string};
  rowIndex: number;
};

import React, {useState} from "react";
import {connect} from "react-redux";
import {UserDto} from "@reside/reside-api-app";
import {Button} from "@reside/ui";
import {useParams} from "react-router";
import {AdminPageTitle} from "../../../atoms/admin-layout/AdminPageTitle";
import {DocumentTitle} from "../../../atoms/document-title";
import {ProfileCard} from "./ProfileCard";
import {ResidentAdmissions} from "../../../atoms/resident-admissions-modal/ResidentAdmissions";
import {useFetchResident} from "../hooks/useFetchResident";
import {BackdropSpinner} from "../../../atoms/spinner";
import {AdminLayoutFullHeightAndTwoCells} from "../../../atoms/admin-layout";
import {BackButton} from "./BackButton";
import {AdminActionRow} from "../../../atoms/admin-action-row";
import {ButtonLink} from "../../../atoms/button-link";
import {ResidentStatusTag} from "./ResidentStatusTag";
import {AppState, select} from "../../../store";
import {InactivationConfirmationModal} from "../../page-admin-residents-forms/atoms/InactivationConfirmationModal";
import {useActivateResident} from "../../page-admin-residents-forms/hooks/useActivateResident";

const Renderer = ({user}: {user: UserDto}): JSX.Element => {
  const {resident_uuid} = useParams<{resident_uuid: string}>();

  const {
    data: resident,
    isLoading,
    remove: clearStaleResident,
  } = useFetchResident(resident_uuid);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isArchived = resident?.is_archived;

  const {mutate: activateResident} = useActivateResident();

  return (
    <AdminLayoutFullHeightAndTwoCells>
      <InactivationConfirmationModal
        residentId={resident?.id}
        isModalOpen={isModalOpen}
        closeModal={() => {
          clearStaleResident();
          setIsModalOpen(false);
        }}
      />
      <BackdropSpinner active={isLoading}>
        <DocumentTitle title="Reside - Admin - Resident Profile" />
        {!isLoading ? (
          <div>
            <AdminActionRow
              title={
                <AdminPageTitle
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 20,
                    maxHeight: "80px",
                  }}
                >
                  <BackButton />
                  <ResidentStatusTag status={resident?.status} />
                </AdminPageTitle>
              }
              actions={
                <>
                  {!isArchived && (
                    <Button
                      color="danger"
                      outline
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      Archive resident
                    </Button>
                  )}

                  {isArchived ? (
                    <Button
                      type="submit"
                      color="primary"
                      onClick={() => {
                        activateResident(resident?.id);
                        clearStaleResident();
                      }}
                    >
                      Unarchive resident
                    </Button>
                  ) : (
                    !Boolean(resident.pcc_patient_id) && (
                      <ButtonLink
                        outline={false}
                        color="primary"
                        to={`/admin/residents/edit/${resident?.id}`}
                      >
                        Edit Resident
                      </ButtonLink>
                    )
                  )}
                </>
              }
              withCreateNewAdmissionButton={false}
            />

            <ProfileCard resident={resident} user={user} />

            <ResidentAdmissions admissions={resident?.admissions} />
          </div>
        ) : null}
      </BackdropSpinner>
    </AdminLayoutFullHeightAndTwoCells>
  );
};

const mapState = (state: AppState) => ({
  user: select.adminSession.user(state),
});

export const ResidentProfile = connect(mapState)(Renderer);

import React from "react";
import {Box} from "@reside/ui";
import {AdminLayoutFullHeightAndTwoCells} from "../../../atoms/admin-layout";
import {AdminActionRow} from "../../../atoms/admin-action-row";
import {useCreateResident} from "../hooks/useCreateResident";
import {DocumentTitle} from "../../../atoms/document-title";
import {ResidentFormik} from "../atoms/ResidentFormik";
import {Spinner} from "../../../atoms/spinner";

export const PageAdminResidentsCreate = (): JSX.Element => {
  const {mutate: createResident, isLoading: isCreating} = useCreateResident();

  return (
    <AdminLayoutFullHeightAndTwoCells>
      {isCreating ? (
        <Spinner />
      ) : (
        <Box position="relative">
          <DocumentTitle title="Reside - Admin - New Resident" />
          <AdminActionRow
            title="New Resident"
            withCreateNewAdmissionButton={false}
          />
          <ResidentFormik
            initialValues={{
              first_name: "",
              middle_name: "",
              last_name: "",
              date_of_birth: "",
              gender: "",
              phone_number: "",
              email: "",
              ssn: "",
              organization_id: null,
              status: "NEW",
            }}
            handleSubmit={createResident}
          />
        </Box>
      )}
    </AdminLayoutFullHeightAndTwoCells>
  );
};

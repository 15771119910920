import React from "react";
import { Text } from "../../../text";
import { Tooltip } from "../../../tooltip";
import { theme } from "../../../theme";
/**
 * @function CellRenderer
 * @description A component to render a demographic cell within a table.
 *              It renders a `Text` component with the cell's value and,
 *              if the cell doesn't match the one being added, renders a
 *              `Tooltip` with a message describing the mismatch.
 *
 * @param {Object} cell - The cell object with `value` and `label` properties.
 * @param {boolean} isMatching - Whether the cell matches the one being added.
 *
 */
export var CellRenderer = function (cell, isMatching) {
    var TextContent = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(Text, { as: "p", fontSize: 3, fontWeight: "medium", style: {
                    backgroundColor: isMatching ? "" : "".concat(theme.color.errorRed, "20"),
                    width: "fit-content",
                } }, cell.value),
            cell.id && (React.createElement(Text, { as: "p", fontSize: 2, fontWeight: "medium", style: { color: theme.color.gray100, marginTop: "4px" } },
                "ID: ",
                cell.id))));
    };
    if (isMatching) {
        return React.createElement(TextContent, null);
    }
    return (React.createElement(Tooltip, { content: "".concat(cell.label, " is different from the one you're adding.") },
        React.createElement(TextContent, null)));
};
